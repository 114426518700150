import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { fill } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import {
  EMAIL_NOT_REQUIRED_NOTE,
  GA_EVENT,
  GA_LABEL,
  MOBILE_NUMBER_NOTE,
  REGEX,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import {
  CREATE_AGENCY_CONTACT,
  UPDATE_AGENCY_CONTACT,
} from '../graphql/Mutation';

const { required, email, characterWithoutWhiteSpace } = formValidatorRules;

const AddContactModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    agencyContactData,
    setAgencyContactData,
    agencyId,
    refetchAgencyContactDetails,
    onlyGetContactData = false,
    setUserList,
    userList,
  } = props;
  const [form] = Form.useForm();
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setAgencyContactData();
  };

  const [createAgencyContact, { loading: createLoading }] = useMutation(
    CREATE_AGENCY_CONTACT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_AGENCY_CONTACT, {
          label: GA_LABEL.ADD_GLOBAL_AGENCY_CONTACT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          agency_id: agencyId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setAgencyContactData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyContactDetails();
      },
    },
  );
  const [updateAgencyContact, { loading: updateLoading }] = useMutation(
    UPDATE_AGENCY_CONTACT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_AGENCY_CONTACT, {
          label: GA_LABEL.EDIT_GLOBAL_AGENCY_CONTACT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          agency_id: agencyId,
          contact_id: agencyContactData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setAgencyContactData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyContactDetails();
      },
    },
  );

  const onFinish = async (formValues) => {
    const newFormValues = {
      ...formValues,
      email: formValues?.email || null,
      phoneNo: formValues?.phoneNo?.split(' ').join(''),
    };
    if (onlyGetContactData) {
      if (isUpdate) {
        fill(userList, newFormValues, agencyId, Number(agencyId) + 1);
      } else {
        setUserList([...userList, newFormValues]);
      }
      setAgencyContactData();
      setShowModal(false);
    } else {
      const variables = isUpdate
        ? {
            data: { ...newFormValues },
            id: agencyContactData?.id,
          }
        : newFormValues;

      try {
        if (isUpdate) {
          await updateAgencyContact({
            variables: { ...variables },
          });
          return;
        }
        await createAgencyContact({
          variables: {
            data: {
              ...variables,
              agencyId,
            },
          },
        });
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="dialog"
      footer={null}
      closable={false}
    >
      <h2>{isUpdate ? 'Edit Agency Contact' : 'Add Agency Contact'}</h2>
      <Form
        form={form}
        initialValues={agencyContactData}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Name cannot be more than 250 characters',
            },
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          rules={[
            email,
            {
              max: 250,
              message: 'Email cannot be more than 250 characters',
            },
          ]}
          name="email"
          label="Email"
        >
          <Input allowClear placeholder="Enter Email" />
        </Form.Item>
        <div className="text-warning note-margin">
          {EMAIL_NOT_REQUIRED_NOTE}
        </div>
        <Form.Item
          label="Mobile Number"
          name="phoneNo"
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
            {
              pattern: REGEX.PHONE,
              message: 'should be a valid contact number',
            },
          ]}
        >
          <Input allowClear placeholder="Enter Mobile Number" />
        </Form.Item>
        <div className="text-danger note-text note-margin ">
          Note : {MOBILE_NUMBER_NOTE}
        </div>
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Designation cannot be more than 250 characters',
            },
          ]}
          name="designation"
          label="Designation"
        >
          <Input allowClear placeholder="Enter Designation" />
        </Form.Item>

        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddContactModal;
