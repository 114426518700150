export const SYSTEM_ROLES = {
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  AUDITOR: 'AUDITOR',
  INSPECTOR: 'INSPECTOR',
};
const {
  SYSTEM_ADMIN,
  PROJECT_ADMIN,
  CHECKLIST_MAKER,
  AUDITOR,
  USER,
  EXTERNAL_AUDITOR,
  INSPECTOR,
} = SYSTEM_ROLES;

export const ALLOWED_ACTIONS = {
  ADD_PROJECT: [SYSTEM_ADMIN],
  EDIT_PROJECT: [SYSTEM_ADMIN],
  RFI_SETUP: [SYSTEM_ADMIN, PROJECT_ADMIN],
  PROJECT_SETTING: [SYSTEM_ADMIN, PROJECT_ADMIN],
  REPORT_CONFIG: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_PROJECT_USER: [SYSTEM_ADMIN, PROJECT_ADMIN],
  VIEW_GALLERY: [SYSTEM_ADMIN, PROJECT_ADMIN],
  VIEW_USERS_PAGE: [SYSTEM_ADMIN],
  VIEW_AGENCY_PAGE: [SYSTEM_ADMIN],
  VIEW_CHECKLISTS_PAGE: [SYSTEM_ADMIN, CHECKLIST_MAKER],
  VIEW_SETUP_PAGE: [SYSTEM_ADMIN, CHECKLIST_MAKER],
  VIEW_LOGS_PAGE: [
    SYSTEM_ADMIN,
    PROJECT_ADMIN,
    AUDITOR,
    USER,
    EXTERNAL_AUDITOR,
  ],
  VIEW_REPORTS_PAGE: [SYSTEM_ADMIN],
  VIEW_PROJECTS_PAGE: [
    INSPECTOR,
    AUDITOR,
    PROJECT_ADMIN,
    SYSTEM_ADMIN,
    USER,
    EXTERNAL_AUDITOR,
  ],
  VIEW_TODO_PAGE: [INSPECTOR, PROJECT_ADMIN, SYSTEM_ADMIN],
  EDIT_PROJECT_EQC: [PROJECT_ADMIN],
  EDIT_INSTRUCTION: [PROJECT_ADMIN],
  EDIT_PROJECT_CHECKLISTS: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_PROJECT_STAGE_ITEM: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_PROJECT_STAGE: [SYSTEM_ADMIN, PROJECT_ADMIN],
  EDIT_PROJECT_AGENCIES: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_AUDITOR_COMMENTS: [AUDITOR, EXTERNAL_AUDITOR],
  VIEW_DASHBOARD_PAGE: [
    INSPECTOR,
    AUDITOR,
    PROJECT_ADMIN,
    SYSTEM_ADMIN,
    USER,
    EXTERNAL_AUDITOR,
  ],
  DELETE_EQC: [SYSTEM_ADMIN],
  PUBLISH_PROJECT_CHECKLIST: [PROJECT_ADMIN, SYSTEM_ADMIN],
  VIEW_TENANT_SELECTION_DROPDOWN: [EXTERNAL_AUDITOR],
  EDIT_ORG_LOGO: [SYSTEM_ADMIN],
  MARK_QC_PASS: [PROJECT_ADMIN, INSPECTOR],
  ADD_TARGET_VALUE: [PROJECT_ADMIN],
  GENERATE_REPORT: [SYSTEM_ADMIN],
  NOTIFICATION_SETTING: [INSPECTOR, PROJECT_ADMIN, SYSTEM_ADMIN, USER],
  EDIT_NOMENCLATURE: [PROJECT_ADMIN, SYSTEM_ADMIN],
};
export const ALLOWED_ACTION_KEYS = {
  ADD_PROJECT: 'ADD_PROJECT',
  VIEW_GALLERY: 'VIEW_GALLERY',
  EDIT_PROJECT: 'EDIT_PROJECT',
  RFI_SETUP: 'RFI_SETUP',
  PROJECT_SETTING: 'PROJECT_SETTING',
  REPORT_CONFIG: 'REPORT_CONFIG',
  ADD_PROJECT_USER: 'ADD_PROJECT_USER',
  VIEW_USERS_PAGE: 'VIEW_USERS_PAGE',
  VIEW_AGENCY_PAGE: 'VIEW_AGENCY_PAGE',
  VIEW_CHECKLISTS_PAGE: 'VIEW_CHECKLISTS_PAGE',
  VIEW_SETUP_PAGE: 'VIEW_SETUP_PAGE',
  VIEW_LOGS_PAGE: 'VIEW_LOGS_PAGE',
  VIEW_TODO_PAGE: 'VIEW_TODO_PAGE',
  VIEW_REPORTS_PAGE: 'VIEW_REPORTS_PAGE',
  VIEW_PROJECTS_PAGE: 'VIEW_PROJECTS_PAGE',
  EDIT_PROJECT_EQC: 'EDIT_PROJECT_EQC',
  EDIT_INSTRUCTION: 'EDIT_INSTRUCTION',
  EDIT_PROJECT_CHECKLISTS: 'EDIT_PROJECT_CHECKLISTS',
  ADD_PROJECT_STAGE_ITEM: 'ADD_PROJECT_STAGE_ITEM',
  ADD_PROJECT_STAGE: 'ADD_PROJECT_STAGE',
  EDIT_PROJECT_AGENCIES: 'EDIT_PROJECT_AGENCIES',
  ADD_AUDITOR_COMMENTS: 'ADD_AUDITOR_COMMENTS',
  VIEW_DASHBOARD_PAGE: 'VIEW_DASHBOARD_PAGE',
  DELETE_EQC: 'DELETE_EQC',
  PUBLISH_PROJECT_CHECKLIST: 'PUBLISH_PROJECT_CHECKLIST',
  VIEW_TENANT_SELECTION_DROPDOWN: 'VIEW_TENANT_SELECTION_DROPDOWN',
  EDIT_ORG_LOGO: 'EDIT_ORG_LOGO',
  MARK_QC_PASS: 'MARK_QC_PASS',
  GENERATE_REPORT: 'GENERATE_REPORT',
  NOTIFICATION_SETTING: 'NOTIFICATION_SETTING',
  ADD_TARGET_VALUE: 'ADD_TARGET_VALUE',
  EDIT_NOMENCLATURE: 'EDIT_NOMENCLATURE',
};
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  FORGOT: '/forgot-password',
  CHANGE: '/change-password',
  RESET: '/reset-password',
  SET_PASSWORD: '/set-password',
  VERIFY: '/verify',
  USERS_MANAGEMENT: '/users',
  AUTHENTICATION: '/authentication',
  PROJECTS: '/projects',
  ADD_PROJECTS: '/projects/add',
  CHECKLISTS: '/checklists',
  LOGS: '/logs',
  AGENCIES: '/agencies',
  UNASSIGN_AGENCIES: '/agencies/unassign',
  REPORTS: '/reports',
  HELP: '/help',
  BUG_REPORT: '/bug-report',
  PROFILE: '/profile',
  TODO: '/todo',
  SETUP: '/setup',
  APPROVER_LINK: '/stage/:id/:token',
  APPROVER_SUCCESS: '/stage/success',
  REPORT_IMAGE_VIEWER: '/viewer/:url',
  MAINTENANCE: '/maintenance',
  stage: '/stage',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'Users',
  PROJECTS: 'Projects',
  CHECKLISTS: 'Checklists',
  LOGS: 'Logs',
  AGENCIES: 'Agencies',
  REPORTS: 'Reports',
  USERS: 'Users',
  SETUP: 'Setup',
  TODO: 'To Do',
  NOTIFICATION: 'Notification',
  PROFILE: 'Profile',
  CHANGE: 'Change Password',
  LOGOUT: 'Logout',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const PROJECT_DETAILS = 'PROJECT_DETAILS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER_KEY = 'USER';
export const TENANT_ID = 'TENANT_ID';
export const DEFAULT_PAGE_SIZE = 10;
export const SCROLL_PERCENT = 0.85;
export const SCROLL_CONST = 5;
export const MAX_FILES_COUNT = 5;
export const FILE_SIZE_20MB = 20971520;
export const FILE_SIZE_2MB = 2097152;
export const FILE_SIZE_10MB = 10485760;
export const DROPDOWN_LIMIT = 25;
export const TIMEOUT_TIME_MS = 3000;
export const POLLING_INTERVAL = 15000;
export const PING_RESOURCE = `/logo192.png`;
export const NUMBER_OF_ROWS_IN_TABLE_SKELETON = 4;
export const NUMBER_OF_SKELETON_CARDS = 3;

export const LAT_LNG = { lat: 21.1702401, lng: 72.8310607 };

export const PERMISSIONS = {
  FINGERPRINT: 'FINGERPRINT',
  LOCATION: 'LOCATION',
};
export const ADD_PROJECT_STEPS = {
  details: 'Project Details',
  users: 'Add Users',
  agencies: 'Add Agencies',
  checklists: 'Add Checklists',
};
export const ADD_AGENCY_STEPS = {
  contact: 'Add Contact',
  agency: 'Add Agency',
};
export const ADD_PROJECT_STEPS_KEYS = {
  DETAILS: 'details',
  USERS: 'users',
  AGENCIES: 'agencies',
  CHECKLISTS: 'checklists',
};
export const AGENCY_TYPE = {
  CONSULTANT: 'CONSULTANT',
  CONTRACTOR: 'CONTRACTOR',
  CLIENT: 'CLIENT',
  DEVELOPER: 'DEVELOPER',
  VENDOR: 'VENDOR',
  OTHERS: 'OTHERS',
  LOCAL: 'LOCAL',
};

export const AGENCY_TYPE_KEYS = {
  CONSULTANT: 'CONSULTANT',
  CONTRACTOR: 'CONTRACTOR',
  CLIENT: 'CLIENT',
  DEVELOPER: 'DEVELOPER',
  VENDOR: 'VENDOR',
  OTHERS: 'OTHERS',
};
export const ACCESS_TYPE = {
  INSPECTION: 'INSPECTION',
  INSTRUCTION: 'INSTRUCTION',
};
export const ALLOWED_ACTION_TYPE = {
  SYSTEM: 'SYSTEM',
  PROJECT: 'PROJECT',
  BOTH: 'BOTH',
};
export const USER_ROLES = {
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
};

export const USER_ROLES_KEYS = {
  USER: 'User',
  EXTERNAL_AUDITOR: 'External auditor',
  CHECKLIST_MAKER: 'Checklist maker',
  SYSTEM_ADMIN: 'System admin',
};
export const ADD_USER_ROLES = {
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER',
};

export const STATUS = {
  RAISED: 'RAISED',
  CLOSED: 'CLOSED',
};

export const STATUS_KEYS = {
  RAISED: 'RAISED',
  CLOSED: 'CLOSED',
  RESPONDED: 'RESPONDED',
};

export const STAGE_STATUS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  PASS: 'PASS',
  REDO: 'REDO',
  SKIP: 'SKIP',
  BYPASS: 'BYPASS',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const STAGE_STATUS_KEYS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  PASS: 'PASS',
  REDO: 'REDO',
  SKIP: 'SKIP',
  BYPASS: 'BYPASS',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const STAGE_ITEM_SEQUENCE = {
  RANDOM: 'RANDOM',
  SEQUENTIAL: 'SEQUENTIAL',
};

export const APPROVAL_TYPE = {
  ON_SITE: 'ON_SITE',
  REMOTE: 'REMOTE',
};

export const EQC_STATUS = {
  PASSED: 'PASSED',
  IN_PROGRESS: 'IN PROGRESS',
  APPROVED: 'APPROVED',
};
export const EQC_STATUS_KEYS = {
  PASSED: 'PASSED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200,
};

export const TYPES = {
  'Y/N': 'Y/N',
  OPTION: 'OPTION',
  TEXT: 'TEXT',
  NUMERIC: 'NUMERIC',
};

export const NUMERIC_LOGIC_OPERATORS = {
  '<=': '<=',
  '>=': '>=',
  '=': '=',
  '<': '<',
  '>': '>',
};

export const TYPES_LABELS = {
  'Y/N': 'Yes/No',
  OPTION: 'Options',
  TEXT: 'Text',
  NUMERIC: 'Numeric',
};

export const PROJECT_USER_ROLES = {
  PROJECT_ADMIN: 'PROJECT ADMIN',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
};

export const EQC_STAGE_CONFIG_LABEL = {
  SKIP: 'SKIP',
  BYPASS: 'BYPASS',
};

export const PROJECT_ROLES = {
  PROJECT_ADMIN: 'PROJECT ADMIN',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
  EXTERNAL_AUDITOR: 'EXTERNAL AUDITOR',
  EQC_MANAGER: 'EQC MANAGER',
};
export const PROJECT_ROLES_KEYS = {
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  EQC_MANAGER: 'EQC_MANAGER',
};
export const PROJECT_ROLES_LABEL = {
  PROJECT_ADMIN: 'Project admin',
  INSPECTOR: 'Inspector',
  AUDITOR: 'Auditor',
  EXTERNAL_AUDITOR: 'External Auditor',
  EQC_MANAGER: 'Eqc manager',
};
export const USER_TAB_ROLES = {
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
};

export const TAB_KEYS = {
  EQC: 'eqc',
  INSTRUCTION: 'issue',
  CHECKLISTS: 'checklists',
  USERS: 'users',
  AGENCIES: 'agencies',
  TARGET: 'target',
  NOMENCLATURE: 'nomenclature',
  RFI: 'rfi',
};
export const CHECKLIST_ITEM_TAB_KEYS = {
  CHECKLISTS: 'checklists',
  APPROVERS: 'approvers',
  SETTING: 'setting',
  RFI: 'rfi',
};

export const PICK_EQC_LABEL = {
  GLOBAL: 'global',
  TEMPLATE: 'template',
  CREATE: 'CREATE',
};

export const LOGS_TABS_KEYS = {
  ACTIVITY: 'activitylogs',
  INSTRUCTION: 'issuelogs',
  GALLERY: 'gallery',
};

export const NOTIFICATION_LABELS = {
  APPROVER_APPROVAL: {
    label: 'Approval given by approver',
    description:
      'Project user will be notified when approval is received from approver.',
  },
  APPROVER_RESPONSE: {
    label: 'Response given by approver',
    description:
      'Approver will be notified when response is given as remote approver for particular Checklist stage.',
  },
  FINAL_EQC_REPORT: {
    label: 'Final EQC Report',
    description:
      'Project user will be notified with final mail report when all the stage of particular Checklist are passed.',
  },
  EQC_REMINDER: {
    label: 'Reminder for EQC',
    description:
      'Project user will be notified on the day of reminder set for particular EQC stage.',
  },
  INSTRUCTION_SUMMARY: {
    label: 'Instruction Summary',
    description:
      'Project user will be notified for all the raised or responded instruction which are not closed.',
  },
};

export const EQC_CONFIG_LABELS = [
  {
    key: 'itemOrderType',
    label: 'Checklist points',
    description: '',
    allowed_module: ['CHECKLIST'],
  },
  {
    key: 'isRfiActive',
    label: 'RFI',
    description: 'Require request to start inspection',
    allowed_module: ['CHECKLIST'],
  },
  {
    key: 'witness',
    label: 'Witness required',
    description:
      'Increase accountability of parties involved during inspection',
    allowed_module: ['RFI', 'CHECKLIST'],
  },
  {
    key: 'drawing',
    label: 'Drawing required',
    description: 'Inspector has to add drawing photo to complete inspection',
    allowed_module: ['RFI', 'CHECKLIST'],
  },
  {
    key: 'skip',
    label: 'Skip stage',
    description:
      'Inspector can skip stage with single button but photo and remark will be mandatory',
    allowed_module: ['CHECKLIST'],
  },
  {
    key: 'pass',
    label: 'Validate stage',
    description:
      'Inspector can pass or seek approval with single button with mandatory photo and remark',
    allowed_module: ['CHECKLIST'],
  },
  {
    key: 'isReRfiRequired',
    label: 'Re-RFI required',
    description:
      'In case of Fail/Redo - RFI initiator has to do re-rfi to start inspection again',
    allowed_module: ['RFI'],
  },
];

export const CSV_SAMPLE_FILES = {
  AGENCY_CSV:
    'https://digiqc-dev.s3.ap-south-1.amazonaws.com/csv/upload-agency-csv-sample.xlsx',
  STAGE_CSV:
    'https://digiqc-dev.s3.ap-south-1.amazonaws.com/csv/stage-csv-sample.xlsx',
  USER_CSV:
    'https://digiqc-dev.s3.ap-south-1.amazonaws.com/csv/user-csv-sample.xlsx',
  NOMENCLATURE_CSV:
    'https://digiqc-dev-public.s3.ap-south-1.amazonaws.com/nomenclature_sample_file.xlsx',
};

export const CROPPER_WIDTH = 133;
export const CROPPER_HEIGHT = 40;
export const CROPPER_WIDTH_FOR_BANNER = 600;
export const CROPPER_HEIGHT_FOR_BANNER = 45;
export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
/* Date and time */

export const DEFAULTDATEFORMAT = 'DD/MM/YYYY';
export const DATEFORMATWITHDASH = 'DD-MM-YYYY';
export const DEFAULTDATETIMEFORMAT = 'DD/MM/YYYY h:mm a';
export const DEFAULTDATETIMEFORMATFORREPORTS = 'YYYY-MM-DD';
export const DATETIMEWITHDIVIDE = 'DD/MM/YYYY | h:mm a';
export const DATETIMEWITHBRACKET = 'DD/MM/YYYY (h:mm a)';
export const DATE_TIME_WITH_AT = 'DD MMM [at] h:mm a';
export const DAY_DATE_MONTH_YEAR_FORMAT = 'ddd Do MMM YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{1,3}[)]?[0-9]{4,12}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  POSITIVE_DECIMAL_NUMBER: /^\d+(\.\d{1,3})?$/,
  ALPHABETS_AND_SPECIAL_CHARACTERS:
    /^[a-zA-Z!@#$%^&*()_+{}[\]:;<>,.?~\\/\s'"-]$/,
  CHARACTER_WITHOUT_WHITESPACE_IN_STARTING_AND_ENDING: /^(?!\s).+(?<!\s)$/,
};

export const QCSTATUS_COLOR = {
  PASS: 'green',
  FAIL: 'red',
  SKIP: '#ffc107',
  REDO: 'red',
  APPROVAL_PENDING: '#ffc107',
  APPROVED: 'green',
};
export const QCSTATUS = {
  PASS: 'Pass',
  FAIL: 'Fail',
  SKIP: 'Skip',
  NOT_RECORDED: 'Not_Recorded',
  REDO: 'Redo',
  APPROVED: 'Approved',
  BYPASS: 'Bypass',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
};

export const UOMS = {
  'CU.M': 'Cubic Meter',
  'CU.FT': 'Cubic Feet',
  'SQ.M': 'Square Meter',
  'SQ.FT': 'Square Feet',
  RMT: 'Running Meter',
  RFT: 'Running Feet',
  KG: 'Kilogram',
  NOS: 'Number',
  JOB: 'Job',
  LUMPSUM: 'Lump Sum',
  TONNE: 'Tonne',
  BRASS: 'Brass',
  YARD: 'Yard',
  SQYARD: 'Square Yard',
  MM: 'Milimeter',
  'SQ.MM': 'Square Milimeter',
  QUINTAL: 'Quintal',
  BAG: 'Bag',
  LITER: 'Liter',
  MILILITER: 'Mililiter',
  DFT: 'Dry Film Thickness',
  INCH: 'Inch',
  'SQ.INCH': 'Square Inch',
  FOOT: 'Foot',
  ACRE: 'Acre',
  HECTARE: 'Hectare',
  MINUTES: 'Minutes',
  SECOND: 'Seconds',
  HOURS: 'Hours',
  MANHOURS: 'Man Hours',
  MANDAYS: 'Man Days',
  'DEGREE CELSIUS': 'Degree Celcius',
};
export const EQC_STAGE_STATUS_KEYS = {
  PASS: 'PASS',
  REDO: 'REDO',
  NOT_DONE: 'NOT_DONE',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
};
export const STAGE_STATUS_TEXT_CLASS = {
  PASS: 'text-success',
  REDO: 'text-danger',
  REJECTED: 'text-danger',
  NOT_DONE: 'text-warning',
  APPROVED: 'text-success',
  ACCEPTED: 'text-success',
  FAIL: 'text-danger',
  APPROVAL_PENDING: 'text-warning',
  BYPASS: 'text-warning',
  SKIP: 'text-secondary',
};
export const STAGE_STATUS_LABEL = {
  PASS: 'Pass',
  FAIL: 'Fail',
  APPROVAL_PENDING: 'Approval pending',
  APPROVED: 'Approved',
  REDO: 'Redo',
  SKIP: 'Skip',
  BYPASS: 'Bypass',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
};

export const CREATE_AGENCY_TYPE = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
};
export const EQC_STATUS_CLASSNAME = {
  NOT_STARTED: 'inactive-tag',
  IN_PROGRESS: 'yellow-tag',
  PASSED: 'active-tag',
  TERMINATE: 'red-tag',
};
export const STAGE_STATUS_CLASSNAME = {
  PASS: 'active-tag',
  FAIL: 'red-tag',
  APPROVAL_PENDING: 'yellow-tag',
  SKIP: 'yellow-tag',
  APPROVED: 'active-tag',
  ACCEPTED: 'active-tag',
  REDO: 'inactive-tag',
  REJECTED: 'inactive-tag',
  NOT_RECORDED: 'inactive-tag',
  BYPASS: 'inactive-tag',
};
export const EQC_STATUS_LABEL = {
  IN_PROGRESS: 'In Progress',
  PASSED: 'Passed',
};

export const PIE_CHART_COLORS = [
  'rgba(242, 95, 92, 1)',
  'rgba(80, 81, 79, 1)',
  'rgba(255, 224, 102, 1)',
  'rgba(36, 123, 160, 1)',
  'rgba(112, 193, 179, 1)',
  'rgba(11, 19, 43, 1)',
  'rgba(28, 37, 65, 1)',
  'rgba(58, 80, 107, 1)',
  'rgba(91, 192, 190, 1)',
  'rgba(111, 255, 233, 1)',
];

export const DASHBOARD_API_ROUTES = {
  AGENCY_WISE_ANALYSIS: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/agency-wise-analysis`,
  PROJECT_WISE_ANALYSIS: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/project-wise-analysis`,
  USER_WISE_ANALYSIS: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/user-wise-analysis`,
  AGENCY_WISE_ANALYSIS_GRAPH: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/agency-wise-analysis-graph`,
  PROJECT_WISE_ANALYSIS_GRAPH: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/project-wise-analysis-graph`,
  USER_WISE_ANALYSIS_GRAPH: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/user-wise-analysis-graph`,
  PROJECT_LIST: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/project-list-wise-summary`,
  USER_LIST: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/user-list-wise-summary`,
  AGENCY_LIST: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/agency-list-wise-summary`,
};

export const REPORTS_TAB_API_ROUTES = {
  EQC_LOG_CSV: '/api/v1/export-csv/reports',
  EQC_LATEST_LOG_CSV: '/api/v1/export-csv/report/export-latest-stages-eqcs',
  EQC_COUNT: '/api/v1/export-csv/report/eqc-stages-status-counts',
  INSTRUCTION_DETAILED: '/api/v1/export-csv/report/instruction/detail-log',
  INSTRUCTION_LATEST_LOG: '/api/v1/export-csv/report/instruction/latest-log',
};

export const BUG_REPORT_FORM_LINK =
  'https://forms.clickup.com/f/jjdr-9475/PWWRFOUEDDB46VVIZ0';

export const HELP_LINK = 'https://digiqc.tawk.help/';

export const SUBSCRIPTION_STATUS = {
  OVER_DUE: 'OVER_DUE',
  ACTIVE: 'ACTIVE',
};
export const WARNINGS = {
  APPROVER_CHANGED:
    'All the pending approval will be sent as a link to new approver.',
  APPROVER_REMOVED:
    "If you unselect Approval Required then any approval pending EQC link won't be sent to anybody and can remain pending.",
  PROJECT_ADMIN_AUTO_ASSIGNED:
    'All project admins will be auto assigned to this Checklist',
  REPORTS_FOR_PROJECT_ADMIN:
    'You will be able to download reports for the project in which you are project admin.',
  REPORTS_FOR_SYSTEM_ADMIN:
    'You will be able to download reports for the project in which you are project admin or you should be System admin to download all project reports.',
};

export const REPORT_NOTES = [
  'EQC QC report link can be generated for maximum 1 month duration.',
  'Max of 5 links can be generated at a time.',
  'Link will be generated from 08:00 pm onward and will be mail to your registered email id once link is generated.',
  'If generated link has more than 50 reports then multiple link in the batch of 50 EQC will be sent in the single mail.',
];

export const GA_EVENT = {
  ADD_AUDITOR_COMMENT: 'add_auditor_comment',
  ADD_GLOBAL_AGENCY: 'add_global_agency',
  ADD_GLOBAL_AGENCY_CONTACT: 'add_global_agency_contact',
  ADD_GLOBAL_CHECKLIST: 'add_global_checklist',
  ADD_GLOBAL_CHECKLIST_STAGE: 'add_global_checklist_stage',
  ADD_GLOBAL_CHECKLIST_STAGE_ITEM: 'add_global_checklist_stage_item',
  ADD_GLOBAL_USER: 'add_global_user',
  ADD_NEW_PROJECT: 'add_new_project',
  ADD_PROJECT_AGENCY: 'add_project_agency',
  ADD_PROJECT_CHECKLIST: 'add_project_checklist',
  ADD_PROJECT_CHECKLIST_STAGE: 'add_project_checklist_stage',
  ADD_PROJECT_CHECKLIST_STAGE_ITEM: 'add_project_checklist_stage_item',
  ADD_PROJECT_RFI_POINT: 'add_project_rfi_point',
  UPDATE_PROJECT_RFI_POINT: 'update_project_rfi_point',
  DELETE_PROJECT_RFI_POINT: 'delete_project_rfi_point',
  REORDER_PROJECT_RFI_POINT: 'REORDER_project_rfi_point',
  ADD_PROJECT_USER: 'add_project_user',
  APPROVE_EQC: 'approve_eqc',
  BUG_REPORT: 'bug_report',
  CHANGE_GLOBAL_USER_STATUS: 'change_global_user_status',
  CLICK_PREVIOUS_BUTTON: 'click_previous_button',
  DELETE_GLOBAL_AGENCY: 'delete_global_agency',
  DELETE_GLOBAL_AGENCY_CONTACT: 'delete_global_agency_contact',
  DELETE_GLOBAL_CHECKLIST: 'delete_global_checklist',
  DELETE_GLOBAL_CHECKLIST_STAGE: 'delete_global_checklist_stage',
  DELETE_GLOBAL_CHECKLIST_STAGE_ITEM: 'delete_global_checklist_stage_item',
  DELETE_PROJECT_AGENCY: 'delete_project_agency',
  DELETE_PROJECT_CHECKLIST_STAGE: 'delete_project_checklist_stage',
  DELETE_PROJECT_CHECKLIST_STAGE_ITEM: 'delete_project_checklist_stage_item',
  DELETE_PROJECT_USER: 'delete_project_user',
  DOWNLOAD_AUDIO: 'download_audio',
  DOWNLOAD_INDIVIDUAL_REPORT: 'download_individual_report',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'download_instruction_final_report',
  DOWNLOAD_INSTRUCTION_REC_REPORT: 'download_instruction_rec_report',
  DOWNLOAD_PROJECT_EQC_DETAILED_REPORT: 'download_project_eqc_detailed_report',
  DOWNLOAD_PROJECT_EQC_FINAL_REPORT: 'download_project_eqc_final_report',
  DOWNLOAD_PROJECT_EQC_REPORT: 'download_project_eqc_report',
  DOWNLOAD_PROJECT_REPORT: 'download_project_report',
  DUPLICATE_GLOBAL_CHECKLIST: 'duplicate_global_checklist',
  DUPLICATE_PROJECT_CHECKLIST: 'duplicate_project_checklist',
  DUPLICATE_PROJECT_CHECKLIST_STAGE: 'duplicate_project_checklist_stage',
  EDIT_GLOBAL_AGENCY: 'edit_global_agency',
  EDIT_GLOBAL_AGENCY_CONTACT: 'edit_global_agency_contact',
  EDIT_GLOBAL_CHECKLIST: 'edit_global_checklist',
  EDIT_GLOBAL_CHECKLIST_STAGE: 'edit_global_checklist_stage',
  EDIT_GLOBAL_CHECKLIST_STAGE_ITEM: 'edit_global_checklist_stage_item',
  EDIT_GLOBAL_USER: 'edit_global_user',
  EDIT_NEW_PROJECT: 'edit_new_project',
  EDIT_PROJECT: 'edit_project',
  EDIT_PROJECT_AGENCY: 'edit_project_agency',
  EDIT_PROJECT_CHECKLIST: 'edit_project_checklist',
  PUBLISH_PROJECT_CHECKLIST_VERSION: 'publish_project_checklist_version',
  PUBLISH_PROJECT_RFI_VERSION: 'publish_project_rfi_version',
  DRAFT_NEW_PROJECT_CHECKLIST_VERSION: 'draft_new_project_checklist_version',
  DRAFT_NEW_PROJECT_RFI_VERSION: 'draft_new_project_rfi_version',
  DISCARD_PROJECT_CHECKLIST_CHANGES: 'discard_project_checklist_changes',
  DISCARD_PROJECT_RFI_CHANGES: 'discard_project_rfi_changes',
  EDIT_PROJECT_CHECKLIST_STAGE: 'edit_project_checklist_stage',
  EDIT_PROJECT_RFI_STAGE: 'edit_project_rfi_stage',
  EDIT_PROJECT_CHECKLIST_STAGE_ITEM: 'edit_project_checklist_stage_item',
  EDIT_PROJECT_USER: 'edit_project_user',
  EDIT_TENANT_LOGO: 'edit_tenant_logo',
  EDIT_USER_LOGO: 'edit_user_logo',
  EDIT_USER_PASSWORD: 'edit_user_password',
  EDIT_USER_PROFILE: 'edit_user_profile',
  EXPORT_AGENCIES: 'export_agencies',
  EXPORT_GLOBAL_CHECKLIST: 'export_global_checklist',
  EXPORT_GLOBAL_USER: 'export_global_user',
  EXPORT_PROJECT_CHECKLIST: 'export_project_checklist',
  EXPORT_PROJECT_CHECKLIST_CSV: 'export_project_checklist_csv',
  EXPORT_PROJECT_NOMENCLATURE_CSV: 'export_project_nomenclature_csv',
  FORGOT_PASSWORD: 'forgot_password',
  GENERATE_PROJECT_REPORT: 'generate_project_report',
  HELP_FORM: 'help_form',
  IMPORTED_GLOBAL_AGENCY: 'imported_global_agency',
  IMPORTED_GLOBAL_CHECKLIST_STAGE: 'imported_global_checklist_stage',
  IMPORTED_NOMENCLATURE: 'imported_nomenclature',
  IMPORTED_GLOBAL_USER: 'imported_global_user',
  FEATURE_UPGRADE_REQUESTED: 'feature_upgrade_requested',
  OPEN_REQUEST_FEATURE_UPGRADE_MODAl: 'open_request_feature_upgrade_modal',
  ONBOARD_PROJECT_AGENCIES: 'onboard_project_agencies',
  ONBOARD_PROJECT_CHECKLIST: 'onboard_project_checklist',
  ONBOARD_PROJECT_USER: 'onboard_project_user',
  OPENED_IMAGE_VIEWER: 'opened_image_viewer',
  PUBLISH_PROJECT_CHECKLIST: 'publish_project_checklist',
  PUBLISH_PROJECT_RFI: 'publish_project_rfi',
  RENAME_PROJECT_EQC: 'rename_project_eqc',
  RENAME_PROJECT_INSTRUCTION: 'rename_project_instruction',
  REORDER_GLOBAL_CHECKLIST_STAGE: 'reorder_global_checklist_stage',
  REORDER_GLOBAL_CHECKLIST_STAGE_ITEM: 'reorder_global_checklist_stage_item',
  REORDER_PROJECT_CHECKLIST_STAGE: 'reorder_project_checklist_stage',
  REORDER_PROJECT_CHECKLIST_STAGE_ITEM: 'reorder_project_checklist_stage_item',
  RESET_PASSWORD: 'reset_password',
  REVISIT_APPROVER_COMMENT: 'revisit_approver_comment',
  SET_PASSWORD: 'set_password',
  UNPUBLISH_PROJECT_CHECKLIST: 'unpublish_project_checklist',
  VIEW_AUDITOR_COMMENT_MODAL: 'view_auditor_comment_modal',
  VIEW_GENERAL_COMMENT_MODAL: 'view_general_comment_modal',
  VIEW_GENERAL_REMARK_MODAL: 'view_general_remark_modal',
  VIEW_LOCATION: 'view_location',
  VIEW_DRAWING_PDF: 'view_drawing_pdf',
  VIEW_PROJECT_EQC_RENAME_MODAL: 'view_project_eqc_rename_modal',
  VIEW_PROJECT_INSTRUCTION_RENAME_MODAL:
    'view_project_instruction_rename_modal',
  VIEW_PAUSE_REMARK_MODAL: 'view_pause_remark_modal',
  CREATE_PROJECT_RFI_DRAFT: 'create_project_rfi_draft',
};

export const GA_LABEL = {
  CREATE_PROJECT_RFI_DRAFT: 'Create Project Rfi Draft',
  ADD_PROJECT_RFI_POINT: 'Add Project Rfi Point',
  UPDATE_PROJECT_RFI_POINT: 'Update Project Rfi Point',
  DELETE_PROJECT_RFI_POINT: 'Delete Project Rfi Point',
  REORDER_PROJECT_RFI_POINT: 'Reorder Project Rfi Point',
  ADD_AUDITOR_COMMENT: 'Add Auditor Comment',
  ADD_GLOBAL_AGENCY: 'Created New Global Agency',
  ADD_GLOBAL_AGENCY_CONTACT: 'Add Global Agency Contact',
  ADD_GLOBAL_CHECKLIST: 'Created New Checklist',
  ADD_GLOBAL_CHECKLIST_STAGE: 'Add Global Checklist Stage',
  ADD_GLOBAL_CHECKLIST_STAGE_ITEM: 'Added Global Checklist Stage Item',
  ADD_GLOBAL_USER: 'Created New Global User',
  ADD_NEW_PROJECT: 'Created New project',
  ADD_PROJECT_AGENCY: 'Add project agency',
  ADD_PROJECT_CHECKLIST: 'Add Project Checklist',
  ADD_PROJECT_CHECKLIST_STAGE: 'Add Project Checklist Stage',
  ADD_PROJECT_CHECKLIST_STAGE_ITEM: 'Add Project Checklist Stage Item',
  ADD_PROJECT_USER: 'Add Project User',
  APPROVE_EQC: 'Approved / Redo EQC',
  BUG_REPORT: 'Opened Bug Report Form',
  CHANGE_GLOBAL_USER_STATUS: 'Change Global Users Status',
  DELETE_GLOBAL_AGENCY: 'Deleted Global Agency',
  DELETE_GLOBAL_AGENCY_CONTACT: 'Delete Global Agency Contact',
  DELETE_GLOBAL_CHECKLIST: 'Deleted Global Checklist',
  DELETE_GLOBAL_CHECKLIST_STAGE: 'Delete Global Checklist Stage',
  DELETE_GLOBAL_CHECKLIST_STAGE_ITEM: 'Delete Global Checklist Stage Item',
  DELETE_PROJECT_AGENCY: 'Delete Project Agency',
  DELETE_PROJECT_CHECKLIST_STAGE: 'Delete Project Checklist Stage',
  DELETE_PROJECT_CHECKLIST_STAGE_ITEM: 'Delete Project Checklist Stage Item',
  DELETE_PROJECT_USER: 'Delete Project User',
  DOWNLOAD_AUDIO: 'Download Audio',
  DOWNLOAD_INDIVIDUAL_REPORT: 'Download Individual Report',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'Download Instruction Final Report',
  DOWNLOAD_INSTRUCTION_REC_REPORT: 'Download Instruction Recommendation Report',
  DOWNLOAD_PROJECT_EQC_DETAILED_REPORT: 'Download EQC Detailed Report',
  DOWNLOAD_PROJECT_EQC_FINAL_REPORT: 'Download EQC Final Report',
  DOWNLOAD_PROJECT_EQC_REPORT: 'Download project eqc report',
  DOWNLOAD_PROJECT_REPORT: 'Download Individual Report',
  DUPLICATE_GLOBAL_CHECKLIST: 'Duplicate Global Checklist',
  DUPLICATE_PROJECT_CHECKLIST: 'Duplicate Project Checklist',
  DUPLICATE_PROJECT_CHECKLIST_STAGE: 'Duplicate Project Checklist Stage',
  EDIT_GLOBAL_AGENCY: 'Edited Global Agency',
  EDIT_GLOBAL_AGENCY_CONTACT: 'Edit Global Agency Contact',
  EDIT_GLOBAL_CHECKLIST: 'Edited Global Checklist',
  EDIT_GLOBAL_CHECKLIST_STAGE: 'Edit Global Checklist Stage',
  EDIT_GLOBAL_CHECKLIST_STAGE_ITEM: 'Edit Global Checklist Stage Item',
  EDIT_GLOBAL_USER: 'Edit Global User',
  EDIT_NEW_PROJECT: 'Edit details of new project',
  EDIT_PROJECT: 'Edit project',
  EDIT_PROJECT_AGENCY: 'Edit project agency',
  EDIT_PROJECT_CHECKLIST: 'Edit Project Checklist',
  PUBLISH_PROJECT_CHECKLIST_VERSION: 'Publish Project Checklist Version',
  PUBLISH_PROJECT_RFI_VERSION: 'Publish Project Rfi Version',
  DRAFT_NEW_PROJECT_CHECKLIST_VERSION: 'Draft New Project Checklist Version',
  DRAFT_NEW_PROJECT_RFI_VERSION: 'Draft New Project Rfi Version',
  DISCARD_PROJECT_CHECKLIST_CHANGES: 'Discard Project Checklist Changes',
  DISCARD_PROJECT_RFI_CHANGES: 'Discard Project Rfi Changes',
  EDIT_PROJECT_CHECKLIST_STAGE: 'Edit Project Checklist Stage',
  EDIT_PROJECT_RFI_STAGE: 'Edit Project Rfi Stage',
  EDIT_PROJECT_CHECKLIST_STAGE_ITEM: 'Edit Project Checklist Stage Item',
  EDIT_PROJECT_USER: 'Edit Project User',
  EDIT_TENANT_LOGO: 'Updated Tenant Logo',
  EDIT_USER_LOGO: 'Updated User Profile Logo',
  EDIT_USER_PASSWORD: 'Edited User Password',
  EDIT_USER_PROFILE: 'Edit User Profile',
  EXPORT_AGENCIES: 'Export Global Agency',
  EXPORT_GLOBAL_CHECKLIST: 'Exported Global Checklist',
  EXPORT_GLOBAL_USER: 'Exported Global Users Data',
  EXPORT_PROJECT_CHECKLIST: 'Export Project Checklist',
  EXPORT_PROJECT_CHECKLIST_CSV: 'Export Project Checklist csv',
  EXPORT_PROJECT_NOMENCLATURE_CSV: 'Export Project Nomenclature csv',
  FORGOT_PASSWORD: 'Clicked Forgot Password',
  GENERATE_PROJECT_REPORT: 'Requested To Generate Project Report',
  HELP_FORM: 'Opened Help Form',
  IMPORTED_GLOBAL_AGENCY: 'Imported Agency Data',
  IMPORTED_GLOBAL_CHECKLIST_STAGE: 'Imported Checklist Data',
  IMPORTED_GLOBAL_USER: 'Imported User Data',
  IMPORTED_NOMENCLATURE: 'Imported Nomenclature',
  ONBOARD_PROJECT_AGENCIES: 'Onboard agencies in new project',
  ONBOARD_PROJECT_CHECKLIST: 'Onboard project Checklists',
  ONBOARD_PROJECT_USER: 'Onboard project users',
  OPENED_IMAGE_VIEWER: 'Opened Image Viewer',
  PUBLISH_PROJECT_RFI: 'Publish Project Rfi',
  RENAME_PROJECT_EQC: 'Rename Project EQC',
  RENAME_PROJECT_INSTRUCTION: 'Rename Project Issues',
  REORDER_GLOBAL_CHECKLIST_STAGE: 'Reorder Global Checklist Stage',
  REORDER_GLOBAL_CHECKLIST_STAGE_ITEM: 'Reorder Global Checklist Stage Item',
  REORDER_PROJECT_CHECKLIST_STAGE: 'Reorder Project Checklist Stage',
  REORDER_PROJECT_CHECKLIST_STAGE_ITEM: 'Reorder Project Checklist Stage Item',
  RESET_PASSWORD: 'Reset Password',
  REVISIT_APPROVER_COMMENT: 'Clicked Revisit Comments Button',
  SET_PASSWORD: 'Set Password',
  UNPUBLISH_PROJECT_CHECKLIST: 'Unpublish Project Checklist',
  VIEW_AUDITOR_COMMENT_MODAL: 'View Auditor Comments Modal',
  VIEW_PAUSE_REMARK_MODAL: 'View Pause Remark Modal',
  VIEW_GENERAL_COMMENT_MODAL: 'View General Comment Modal',
  VIEW_GENERAL_REMARK_MODAL: 'View General Remark Modal',
  VIEW_LOCATION: 'Clicked on view location link',
  VIEW_DRAWING_PDF: 'Clicked on view drawing pdf link',
  VIEW_PROJECT_EQC_RENAME_MODAL: 'View Project Eqc Rename Modal',
  VIEW_PROJECT_INSTRUCTION_RENAME_MODAL:
    'View Project Instruction Rename Modal',
  FEATURE_UPGRADE_REQUESTED: 'Feature Upgrade Requested',
  OPEN_REQUEST_FEATURE_UPGRADE_MODAl: 'Open Request Feature Upgrade Modal',
};

export const INS_STATUS_KEYS = {
  NOTICE: 'NOTICE',
  RAISED: 'RAISED',
  RESPONDED: 'RESPONDED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
};

export const INS_STATUS_CLASSNAME = {
  RAISED: 'yellow-tag',
  NOTICE: 'active-tag',
  RESPONDED: 'inactive-tag',
  REJECTED: 'red-tag',
  CLOSED: 'active-tag',
};

export const INS_STATUS_LABEL = {
  RAISED: 'Raised',
  NOTICE: 'Notice',
  RESPONDED: 'Responded',
  REJECTED: 'Rejected',
  CLOSED: 'Closed',
};

export const PROJECT_CHECKLIST_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
};

export const CHECKLIST_STATUS_LABEL = {
  PUBLISHED: 'LIVE',
  ARCHIVED: 'ARCHIVE',
  UNPUBLISHED: 'DRAFT',
  DRAFT: 'DRAFT',
};

export const CHECKLIST_STATUS_CLASSNAME = {
  UNPUBLISHED: 'draft-tag',
  DRAFT: 'draft-tag',
  PUBLISHED: 'active-tag',
  ARCHIVED: 'inactive-tag',
};
export const IMAGE_FILTERS = {
  ALL_IMAGES: 'All Image',
  INSPECTIONS: 'Inspection',
  INSTRUCTIONS: 'Instruction',
};

export const ADDONS = {
  WHATSAPP: 'WHATSAPP',
  INSTRUCTION_WHATSAPP: 'INSTRUCTION_WHATSAPP',
  MULTI_LEVEL_APPROVAL: 'MULTI_LEVEL_APPROVAL',
  INSPECTION_GALLERY_ACCESS: 'INSPECTION_GALLERY_ACCESS',
  TARGET_ACTUAL_METRIC: 'TARGET_ACTUAL_METRIC',
  NOMENCLATURE: 'NOMENCLATURE',
  RFI: 'RFI',
  REPORT_CONFIG: 'REPORT_CONFIG',
};
export const CONFIRMATION_MODAL_TITLES = {
  EDIT: {
    TITLE:
      'This will allow you to edit the checklist. Are you sure you want to edit this checklist?',
    RFI_TITLE:
      'This will allow you to edit the RFI. Are you sure you want to edit this RFI?',
    BUTTON_TITLE: 'Yes',
  },
  UNARCHIVE: {
    TITLE:
      'Checklist will be available in editable mode and has to make live to do inspection. Are you sure to unarchive this checklist?',
    RFI_TITLE:
      'RFI will be available in editable mode and has to make live to do inspection. Are you sure to unarchive this RFI?',
    BUTTON_TITLE: 'Yes',
  },
  ARCHIVE: {
    TITLE: 'This checklist will no longer be active. Are you sure?',
    RFI_TITLE: 'This RFI will no longer be active. Are you sure?',
    BUTTON_TITLE: 'Archive',
  },
  SAVE: {
    TITLE:
      'Once saved, previous version will no longer be available. Are you sure?',
    RFI_TITLE:
      'Once saved, previous version will no longer be available. Are you sure?',
    BUTTON_TITLE: 'Confirm',
  },
  DISCARD: {
    TITLE: 'All changes made will be lost. Are you sure?',
    RFI_TITLE: 'All changes made will be lost. Are you sure?',
    BUTTON_TITLE: 'Discard',
  },
  MULTIPLE_USER: {
    TITLE: '',
    BUTTON_TITLE: 'Discard',
  },
};
export const CONFIRMATION_TYPES = {
  EDIT: 'EDIT',
  ARCHIVE: 'ARCHIVE',
  SAVE: 'SAVE',
  DISCARD: 'DISCARD',
  MULTIPLE_USER: 'MULTIPLE_USER',
  UNARCHIVE: 'UNARCHIVE',
};

export const MOBILE_NUMBER_NOTE =
  'Please do not include "0" as a prefix when adding a mobile number';

export const EMAIL_NOT_REQUIRED_NOTE =
  'Email notification will not be sent if email is not added in agency contact';

export const EXCEPT_THIS_SYMBOLS = ['e', 'E', '+', '-', '.'];

export const ELEMENT_IDS = {
  TIME_STAMP: 'time-stamp',
};

export const NOMENCLATURE_TREE_TITLE_CLASS_BY_LEVEL = {
  0: 'l0-tree-title',
  1: 'l1-tree-title',
  2: 'l2-tree-title',
  3: 'l3-tree-title',
  4: 'l4-tree-title',
  5: 'l5-tree-title',
  6: 'l6-tree-title',
  7: 'l7-tree-title',
  8: 'l8-tree-title',
  9: 'l9-tree-title',
};

export const NOMENCLATURE_CHART_NODE_COLORS = {
  0: {
    border: '#FDA793',
    background: 'transparent',
  },
  1: {
    border: '#1890FF',
    background: '#E6F7FF',
  },
  2: {
    border: '#F5222D',
    background: '#FFF1F0',
  },
  3: {
    border: '#FA8C16',
    background: '#FFF7E6',
  },
  4: {
    border: '#722ED1',
    background: '#F9F0FF',
  },
  5: {
    border: '#52C41A',
    background: '#F6FFED',
  },
  6: {
    border: '#13C2C2',
    background: '#E6FFFB',
  },
  7: {
    border: '#2F54EB',
    background: '#F0F5FF',
  },
  8: {
    border: '#EB2F96',
    background: '#FFF0F6',
  },
  9: {
    border: '#B3B6C3',
    background: '#FFF',
  },
  disabled: {
    border: '#000',
    background: '#b0adab',
  },
};

export const NOMENCLATURE_ACTION_BUTTON_URL = {
  CREATE: 'https://help.digiqc.com/en/articles/146-2-create',
  IMPORT: 'https://help.digiqc.com/en/articles/147-3-import',
  INDENT: 'https://help.digiqc.com/en/articles/149-4-indent',
  OUTDENT: 'https://help.digiqc.com/en/articles/148-5-outdent',
  DUPLICATE: 'https://help.digiqc.com/en/articles/150-6-duplicate',
  DELETE: 'https://help.digiqc.com/en/articles/151-7-delete',
  DISABLE: 'https://help.digiqc.com/en/articles/152-8-disable',
  ENABLE: 'https://help.digiqc.com/en/articles/154-10-enable',
};

export const REQUEST_FEATURE_UPGRADE_KEYS = {
  STAGE_APPROVAL: 'STAGE_APPROVAL',
  STAGE_APPROVAL_MAX_LEVEL: 'STAGE_APPROVAL_MAX_LEVEL',
  STAGE_MAX_LIMIT: 'STAGE_MAX_LIMIT',
  INSPECTION_ASSET_LIMIT: 'INSPECTION_ASSET_LIMIT',
  ISSUE_TAG: 'ISSUE_TAG',
  ISSUE_RECOMMENDATION: 'ISSUE_RECOMMENDATION',
  RFI: 'RFI',
  REPORT_CONFIG: 'REPORT_CONFIG',
};

export const REPORT_CONFIG_DETAILS = [
  {
    title: 'Include Organization Logo',
    type: 'radio',
    options: ['Yes', 'No'],
    description:
      'Choose whether to show the organization logo in the report header.',
    formName: 'organizationLogoRequired',
  },
  {
    title: 'Logo Size',
    type: 'dropdown',
    description:
      'Select the size of the organization logo and project logo to display.',
    options: ['small', 'medium', 'large'],
    formName: 'organizationLogoSize',
  },
  {
    title: 'Add Project logo',
    description: 'Upload up to 4 logos to display in the report header.',
    type: 'upload',
  },
  {
    title: 'Include Organization Name',
    type: 'radio',
    description:
      "Choose whether to show the organization's name in the report header below logo.",
    options: ['Yes', 'No'],
    formName: 'organizationNameRequired',
  },
  {
    title: 'Include Report Title',
    type: 'radio',
    description: 'Choose whether to display the report title.',
    options: ['Yes', 'No'],
    formName: 'reportTitleRequired',
  },
  {
    title: 'Show Status',
    type: 'radio',
    description: 'Choose whether to display the statuses in report.',
    options: ['Yes', 'No'],
    formName: 'statusRequired',
  },
  {
    title: 'Photo Size',
    type: 'dropdown',
    description:
      'Select the size of the project photo to display in the report.',
    options: ['small', 'large'],
    formName: 'photoSize',
  },
];

export const REPORT_TYPE = {
  PRINTABLE: 'PRINTABLE',
  SHAREABLE: 'SHAREABLE',
};
