import { useQuery } from '@apollo/client';
import { Col, message, Row } from 'antd';
import * as eva from 'eva-icons';
import { filter, isNaN } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import { ForwardArrow, ProjectIcon } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  INS_STATUS_KEYS,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import EllipsisText from '../../../../../../../components/EllipsisText';
import Error404 from '../../../../../../../Error404';
import history from '../../../../../../../historyData';
import {
  GET_PROJECT_INSTRUCTION,
  GET_SINGLE_PROJECT,
} from '../../../../../graphql/Queries';
import CloseDetails from './CloseDetails';
import InstructionMoreDetails from './InstructionMoreDetails';
import InstructionSummary from './InstructionSummary';
import RecommendationDetails from './RecommendationDetails';
import RejectionDetails from './RejectionDetails';
import ResponseDetails from './ResponseDetails';

const InstructionDetails = () => {
  const {
    navigate,
    params: { projectId, instructionId },
    location,
  } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const { dispatch } = useContext(AppContext);

  const instructionFilter =
    typeof location?.state?.instructionFilter === 'string'
      ? JSON.parse(location?.state?.instructionFilter)
      : location?.state?.instructionFilter;
  const instructionPagination = location?.state?.instructionPagination;
  const selectedUserRecord = location?.state?.selectedUserRecord;
  const selectAgencyRecord = location?.state?.selectAgencyRecord;
  const currentPath = location?.state?.currentPath;
  const projectRecord = location?.state?.projectRecord;
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: projectData } = useQuery(GET_SINGLE_PROJECT, {
    variables: {
      id: projectId,
    },
    onCompleted(res) {
      dispatch({
        type: 'SET_PROJECT_DETAILS',
        data: JSON.stringify(res?.getProject),
      });
    },
  });

  useEffect(() => {
    const unlisten = history.listen((props) => {
      if (props.action === 'POP') {
        navigate(props.location.pathname, {
          state: {
            navFilter,
            navPagination,
            instructionFilter,
            instructionPagination,
            selectedUserRecord,
            selectAgencyRecord,
            projectRecord,
          },
        });
      }
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  const { data: instructionData } = useQuery(GET_PROJECT_INSTRUCTION, {
    fetchPolicy: 'network-only',
    variables: {
      id: instructionId,
    },
    skip: isNaN(Number(instructionId)),
    onError() {
      navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}`);
      setTimeout(() => {
        message.destroy();
        message.error('Instruction not found!');
      }, 1000);
    },
  });
  const respondedDetails = filter(
    instructionData?.getInstruction?.instructionIssueHistories,
    ({ status }) => status === INS_STATUS_KEYS.RESPONDED,
  );
  const rejectedDetails = filter(
    instructionData?.getInstruction?.instructionIssueHistories,
    ({ status }) => status === INS_STATUS_KEYS.REJECTED,
  );

  if (isNaN(Number(projectId)) || isNaN(Number(instructionId))) {
    return <Error404 />;
  }
  return (
    <>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex align-center">
            <ProjectIcon
              onClick={() => {
                navigate(ROUTES.PROJECTS, {
                  state: {
                    navFilter,
                    navPagination,
                  },
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1
              className="pointer text-primary mr-10"
              onClick={() => {
                navigate(
                  `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}`,
                  currentPath?.split('/')?.[
                    currentPath?.split('/')?.length - 1
                  ] === TAB_KEYS?.INSTRUCTION
                    ? {
                        state: {
                          navFilter,
                          navPagination,
                          instructionFilter,
                          instructionPagination,
                          selectedUserRecord,
                          selectAgencyRecord,
                        },
                      }
                    : {},
                );
              }}
            >
              {projectData?.getProject?.name}
            </h1>
            <ForwardArrow />
            <EllipsisText
              className="h1-typography"
              text={instructionData?.getInstruction?.name}
            />
          </div>
        </div>
        <div
          className={`mobile-card-wrapper ${
            isDesktopViewport ? '' : 'd-flex flex-vertical'
          }`}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InstructionSummary
                instructionData={instructionData?.getInstruction}
              />
            </Col>
            <Col
              span={
                isDesktopViewport &&
                instructionData?.getInstruction?.status !==
                  INS_STATUS_KEYS.NOTICE
                  ? 12
                  : 24
              }
            >
              <InstructionMoreDetails
                instructionData={instructionData?.getInstruction}
              />
            </Col>
            {instructionData?.getInstruction?.status !==
              INS_STATUS_KEYS.NOTICE && (
              <Col
                span={isDesktopViewport ? 12 : 24}
                className="closing-details"
              >
                <CloseDetails
                  instructionData={instructionData?.getInstruction}
                />
              </Col>
            )}
            {instructionData?.getInstruction?.instructionHistory?.length >
              0 && (
              <Col span={24}>
                <RecommendationDetails
                  data={instructionData?.getInstruction?.instructionHistory}
                />
              </Col>
            )}
            {respondedDetails?.length > 0 && (
              <Col span={24}>
                <ResponseDetails data={respondedDetails} />
              </Col>
            )}
            {rejectedDetails?.length > 0 && (
              <Col span={24}>
                <RejectionDetails data={rejectedDetails} />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default InstructionDetails;
