import { Popover } from 'antd';
import React from 'react';

const CommonPopover = (props) => {
  const { getPopupContainer, content, children, ...rest } = props;
  return (
    <Popover
      getPopupContainer={(trigger) =>
        typeof getPopupContainer === 'function'
          ? getPopupContainer(trigger)
          : trigger.parentNode
      }
      content={content}
      {...rest}
    >
      {children}
    </Popover>
  );
};

export default CommonPopover;
