import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import React from 'react';
import { PUBLISH_PROJECT_NOMENCLATURE } from '../../../../graphql/Mutation';

const GoLiveModal = ({
  showModal,
  setShowModal,
  handleRefetch,
  setCheckedTreeNode,
  setCheckedKeys,
  projectId,
  data,
}) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const [publishProjectNomenclature, { loading }] = useMutation(
    PUBLISH_PROJECT_NOMENCLATURE,
    {
      onCompleted() {
        setCheckedKeys();
        setCheckedTreeNode();
        handleCancel();
        handleRefetch();
      },
      onError() {},
    },
  );

  const handleGoLive = async () => {
    await publishProjectNomenclature({
      variables: {
        projectId,
      },
    });
  };
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          {data?.[0]?.isNomenclaturePublished ? (
            <>
              <h2>Disable Nomenclature</h2>
              <p>
                This will deactivate the nomenclature for this project and
                mobile user cannot use it which adding EQC location.
              </p>
              <p>Are you sure?</p>
            </>
          ) : (
            <>
              <h2>Going live</h2>
              <p>Awesome! 👌</p>
              <p>
                Once live all mobile users shall see this naming convention.
              </p>
            </>
          )}

          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleGoLive}
              disabled={loading}
              loading={loading}
            >
              {data?.[0]?.isNomenclaturePublished ? 'Disable' : 'Go Live'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GoLiveModal;
