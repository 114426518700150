import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query userList($filter: UsersListFilter!) {
    userList(filter: $filter) {
      total
      activeUserCount
      totalUserCount
      data {
        id
        name
        email
        isDisabled
        isActive
        roles
        phoneNo
        countryCode
        assignedAsApprover
        lastUpdated
        inspectionCount
        instructionCount
        approvalGivenCount
        projectUsers {
          project {
            name
          }
        }
      }
    }
  }
`;

export const USER_ONBOARD_DROPDOWN_LIST = gql`
  query userOnboardDropdownList($filter: UsersListFilter!) {
    userOnboardDropdownList(filter: $filter) {
      total
      activeUserCount
      totalUserCount
      data {
        id
        name
        email
        isDisabled
        isActive
        roles
        phoneNo
        countryCode
        assignedAsApprover
        lastUpdated
        inspectionCount
        instructionCount
        approvalGivenCount
        projectUsers {
          project {
            name
          }
        }
      }
    }
  }
`;

export const USER_EQC_TYPE_STAGE_APPROVAL_LIST = gql`
  query userEqcTypeStageApprovalList(
    $filter: ProjectEqcTypeStageApprovalFilter!
  ) {
    userEqcTypeStageApprovalList(filter: $filter) {
      total
      requiredProjectEqcTypeStageLevel {
        projectEqcTypeStageId
        levelNumber
      }
      data {
        id
        stageId
        name
        projectName
        projectEqcTypeName
        levelNumber
        levelName
        projectId
        projectUserId
        projectEqcTypeStageApprovals {
          id
          internalApproverId
          externalApproverId
          levelNumber
          levelName
          internalApproverName
          externalApproverName
        }
      }
    }
  }
`;

export const GET_USERS_DROPDOWN_LIST = gql`
  query userDropdownList($filter: UsersListFilter!) {
    userDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getLoggedInUser {
    getLoggedInUser {
      id
      name
      email
      password
      roles
      profileImage
      phoneNo
      createdBy
      profileImage
      isDisabled
      isActive
      countryCode
      forcePasswordReset
      tenants {
        id
        tenant {
          id
          organizationName
        }
      }
      projectUsers {
        id
        roles
        access
      }
      tenantUser {
        id
        tenant {
          id
          organizationName
          adminEmail
          ownerName
          phoneNumber
          logo
          userLimit
          inspectionAssetLimit
          instructionAssetLimit
          access
          timeZone
          addOn
          maxApprovalLevel
          featureApprovalRequestConfig {
            stageApprovalRequestSent
            stageApprovalMaxLevelRequestSent
            stageMaxLimitRequestSent
            issueTagRequestSent
            issueRecommendationRequestSent
            inspectionAssetLimitRequestSent
          }
          featureConfig {
            stageMaxLimit
            issueTag
            issueRecommendation
          }
          subscription {
            id
            validTill
            status
            planObj {
              id
              name
            }
          }
        }
      }
    }
  }
`;
