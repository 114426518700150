import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { isNaN, nth } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import Error404 from '../../../../Error404';
import { EditIcon, Export, ForwardArrow } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import useRouter from '../../../../common/useRouter';
import EllipsisText from '../../../../components/EllipsisText';
import history from '../../../../historyData';
import AddEditEqcModal from '../../AddEditEqcModal';
import { GET_EQC_TYPE } from '../../graphql/Queries';
import EqcDetailContent from './EqcDetailContent';

const EqcDetails = () => {
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [showModal, setShowModal] = useState(false);
  const [eqcData, setEqcData] = useState();
  const {
    navigate,
    location,
    params: { eqcTypeId },
  } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const eqcFilter = location?.state?.eqcFilter;

  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [webEqcTypeList, { data: eqcTypeData, refetch }] = useLazyQuery(
    GET_EQC_TYPE,
    { fetchPolicy: 'network-only' },
  );

  useEffect(() => {
    const unlisten = history.listen((props) => {
      if (props.action === 'POP') {
        navigate(props.location.pathname, {
          state: {
            navFilter,
            eqcFilter,
            navPagination,
          },
        });
      }
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  useEffect(() => {
    if (!isNaN(Number(eqcTypeId))) {
      webEqcTypeList({
        variables: {
          filter: {
            eqcTypeId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = nth(eqcTypeData?.eqcTypeList?.data, 0);
  const renderDownloadPdfButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/eqc-types/${eqcTypeId}/export?access_token=Bearer ${token}`;
    return (
      <Button
        type="ghost"
        className="export-button ant-btn-icon-only d-flex b-0 justify-center width-percent-100"
        target="_blank"
        href={url}
        onClick={() =>
          Event(GA_EVENT.EXPORT_GLOBAL_CHECKLIST, {
            label: GA_LABEL.EXPORT_GLOBAL_CHECKLIST,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            checklist_id: eqcTypeId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
          })
        }
      >
        PDF
      </Button>
    );
  };
  const renderDownloadCsvButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/report/eqc-types/${eqcTypeId}/export?access_token=Bearer ${token}`;
    return (
      <Button
        type="ghost"
        className="export-button ant-btn-icon-only d-flex b-0 justify-center width-percent-100"
        target="_blank"
        href={url}
        onClick={() =>
          Event(GA_EVENT.EXPORT_PROJECT_CHECKLIST_CSV, {
            label: GA_LABEL.EXPORT_PROJECT_CHECKLIST_CSV,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            checklist_id: eqcTypeId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
          })
        }
      >
        CSV
      </Button>
    );
  };
  if (isNaN(Number(eqcTypeId))) {
    return <Error404 />;
  }
  return (
    <div>
      {showModal && (
        <AddEditEqcModal
          showModal={showModal}
          setShowModal={setShowModal}
          setEqcData={setEqcData}
          eqcData={eqcData}
          isUpdate={!!eqcData}
          refetchUserData={refetch}
        />
      )}
      <div className="details-header d-flex justify-between page-header">
        <h2 className="d-flex align-center">
          <span
            onClick={() => {
              navigate(`${ROUTES.SETUP}${ROUTES.CHECKLISTS}`, {
                state: {
                  navFilter,
                  navPagination,
                },
              });
            }}
          >
            <i data-eva="pantone-outline" className="pointer" />
          </span>
          <ForwardArrow />
          <EllipsisText className="h1-typography" text={fetchData?.name} />
        </h2>
        <div className="d-flex">
          <Dropdown
            menu={{
              items: [
                {
                  key: 'pdf',
                  label: renderDownloadPdfButton(),
                },
                {
                  key: 'csv',
                  label: renderDownloadCsvButton(),
                },
              ],
            }}
            trigger={['click']}
            placement="bottom"
          >
            <Button
              shape="round"
              icon={<Export height="26px" />}
              className="export-button d-flex b-0"
            >
              {isDesktopViewport && 'Export'}
            </Button>
          </Dropdown>
          <Button
            shape="round"
            type="primary"
            icon={<EditIcon />}
            className="edit-button-eqc d-flex"
            onClick={() => {
              setEqcData(fetchData);
              setShowModal(true);
            }}
          >
            {isDesktopViewport && 'Edit'}
          </Button>
        </div>
      </div>
      <div className={clsx(isDesktopViewport && 'mt-15')}>
        <EqcDetailContent eqcTypeData={fetchData} />
      </div>
    </div>
  );
};

export default EqcDetails;
