import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'antd';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  Import,
} from '../../../../../assets/svg';
import {
  BREAKPOINTS,
  CSV_SAMPLE_FILES,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  REQUEST_FEATURE_UPGRADE_KEYS,
} from '../../../../../common/constants';
import { Event } from '../../../../../common/trackEvents';
import useRouter from '../../../../../common/useRouter';
import CommonImportModal from '../../../../../components/CommonImportModal';
import CommonTable from '../../../../../components/CommonTable';
import CommonTooltip from '../../../../../components/CommonTooltip';
import EllipsisText from '../../../../../components/EllipsisText';
import Portal from '../../../../../components/Portal';
import UpgradeModal from '../../../../../components/UpgradeModal';
import {
  DELETE_STAGE,
  IMPORT_EQC_TYPE_CSV,
  REORDER_STAGE,
} from '../../../graphql/Mutation';
import { GET_STAGE_LIST } from '../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditStageModal from './AddEditStageModal';

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <DragIcon
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Rows = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const StageListTable = ({
  setStageId,
  stageId,
  setModalVisible,
  setModalTitle,
}) => {
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const {
    params: { eqcTypeId },
  } = useRouter();
  const [showImportModal, setShowImportModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const currentTenant = getTenantUser();

  const sampleFileUrl = CSV_SAMPLE_FILES.STAGE_CSV;
  const list = [
    'Stage Type',
    'Checklist Point',
    'Input',
    'Description(Text Only)',
  ];
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const initialStageFilter = {
    skip: 0,
    limit: 10,
    eqcTypeId: Number(eqcTypeId),
    sortBy: { field: 'order', order: 'ASC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [deleteStageModal, setDeleteStageModal] = useState(false);
  const [stageData, setStageData] = useState();
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [stageFilter, setStageFilter] = useState(initialStageFilter);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [fetchStageData, { loading }] = useLazyQuery(GET_STAGE_LIST, {
    variables: { filter: stageFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setStageId(res?.eqcTypeStageList?.data[0]?.id);
      const pagination = {
        ...paginationProp,
        total: res?.eqcTypeStageList?.total,
      };
      setPaginationProp(pagination);
      setDataSource(sortBy(res?.eqcTypeStageList?.data, ['order']));
    },
    onError() {},
  });
  const handleRefetchAfterDelete = () => {
    const newSkip =
      dataSource?.length === 1
        ? Math.max(0, stageFilter?.skip - paginationProp?.pageSize)
        : stageFilter?.skip;
    setStageFilter({
      ...stageFilter,
      skip: newSkip,
    });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip: newSkip,
        },
      },
    });
  };
  const [deleteStage] = useMutation(DELETE_STAGE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_CHECKLIST_STAGE, {
        label: GA_LABEL.DELETE_GLOBAL_CHECKLIST_STAGE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        checklist_id: eqcTypeId,
        stage_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteStageModal(false);
      handleRefetchAfterDelete();
    },
  });

  const [updateStageImport, { loading: importLoading }] = useMutation(
    IMPORT_EQC_TYPE_CSV,
    {
      onError() {},
    },
  );

  useEffect(() => {
    fetchStageData({ variables: { filter: stageFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchStageDetails = () => {
    fetchStageData({ variables: { filter: { ...stageFilter } } });
  };

  const [reorderStage, { loading: reorderLoading }] = useMutation(
    REORDER_STAGE,
    {
      onCompleted() {
        Event(GA_EVENT.REORDER_GLOBAL_CHECKLIST_STAGE, {
          label: GA_LABEL.REORDER_GLOBAL_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          checklist_id: eqcTypeId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        fetchStageData();
      },
      onError() {},
    },
  );

  const handleAddEditUser = (record) => {
    if (record) {
      setStageData(record);
    } else {
      setStageData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageFilter({ ...stageFilter, skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };
  const handleImport = () => {
    setShowImportModal(true);
  };
  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    handleAddEditUser(record);
  };
  const handleDelete = (e, record) => {
    e?.stopPropagation?.();
    setTitle('Stage');
    setName(record?.name);
    setMutationId(record?.id);
    setDeleteStageModal(true);
  };
  const columns = [
    {
      width: '20%',
      align: 'center',
      className: 'drag-visible',
      render: () => (
        <CommonTooltip
          getPopupContainer={() =>
            // eslint-disable-next-line no-undef
            document.querySelector('.ant-table-content')
          }
          title="Change order"
        >
          <div>
            <DragHandle />
          </div>
        </CommonTooltip>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      className: 'drag-visible',
      key: 'name',
      width: '70%',
      render: (record) => (
        <div>
          <EllipsisText text={record} />
        </div>
      ),
    },
    {
      key: 'action',
      render: (record) => {
        return (
          <div className="d-flex action-icons">
            <CommonTooltip trigger="focus" title="Edit">
              <Button
                shape="round"
                icon={<EditButton />}
                className="edit-button pointer b-0"
                onClick={(e) => handleEdit(e, record)}
              />
            </CommonTooltip>
            <CommonTooltip trigger="focus" title="Delete">
              <Button
                className="delete-button pointer b-0"
                shape="round"
                icon={<DeleteButton />}
                onClick={(e) => handleDelete(e, record)}
              />
            </CommonTooltip>
          </div>
        );
      },
    },
  ];

  const DraggableBodyRow = async ({ over, active }) => {
    if (!active || !over || active?.id === over?.id) return;
    const overIndex = over?.data?.current?.sortable?.index;
    const activeIndex = active?.data?.current?.sortable?.index;
    const data = [...dataSource];
    setDataSource(arrayMove(data, activeIndex, overIndex));
    try {
      const { errors } = await reorderStage({
        variables: {
          data: {
            eqcTypeStageIds: [active?.id, over?.id],
            eqcTypeId: Number(eqcTypeId),
          },
        },
      });
      if (errors) {
        throw errors;
      }
    } catch (error) {
      setDataSource(data);
    }
  };

  return (
    <>
      {showModal && (
        <AddEditStageModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageData}
          isUpdate={!!stageData}
          setStageData={setStageData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          eqcTypeId={eqcTypeId}
        />
      )}
      {deleteStageModal && (
        <DeleteModalStage
          showModal={deleteStageModal}
          setShowModal={setDeleteStageModal}
          title={title}
          name={name}
          deleteStage={deleteStage}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      {showImportModal && (
        <CommonImportModal
          showImportModal={showImportModal}
          setShowImportModal={setShowImportModal}
          title="Checklist Stages"
          sampleFileUrl={sampleFileUrl}
          list={list}
          updateStageImport={updateStageImport}
          refetchData={fetchStageData}
          filePrefix="eqcTypeCsv"
          eqcTypeId={eqcTypeId}
          stageUi
          importLoading={importLoading}
        />
      )}
      <Portal portalId="eqc-details-btn">
        <Portal portalId="add-btn">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            icon={<AddButton />}
            className="add-button b-0"
            onClick={() => {
              if (
                paginationProp?.total <
                currentTenant?.tenant?.featureConfig?.stageMaxLimit
              ) {
                handleAddEditUser();
              } else {
                setShowUpgradeModal(true);
              }
            }}
          >
            {isDesktopViewport && 'Add'}
          </Button>
        </Portal>
        <Button
          shape="round"
          icon={<Import />}
          className="import-button b-0"
          onClick={handleImport}
        >
          {isDesktopViewport && 'Import'}
        </Button>
      </Portal>
      <div className="pointer">
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={DraggableBodyRow}
          sensors={sensors}
        >
          <SortableContext
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <CommonTable
              components={{ body: { row: Rows } }}
              columns={columns}
              dataSource={dataSource || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey="id"
              loading={loading || reorderLoading}
              rowClassName={(record) => {
                if (record?.id === stageId) return 'row-dark';
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (record?.id) {
                      setStageId(record?.id);
                      setModalVisible(true);
                      setModalTitle(record?.name);
                    }
                  },
                };
              }}
            />
          </SortableContext>
        </DndContext>
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.STAGE_MAX_LIMIT}
          isAlreadyRequested={
            currentTenant?.tenant?.featureApprovalRequestConfig
              ?.stageMaxLimitRequestSent
          }
        />
      )}
    </>
  );
};

export default StageListTable;
