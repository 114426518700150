import { Badge, Button, Col, Divider, Image, Row, Space, Tag } from 'antd';
import clsx from 'clsx';
import { filter, includes, map } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { CloseIcon, MessageIcon } from '../../assets/svg';
import {
  APPROVAL_TYPE,
  BREAKPOINTS,
  DATE_TIME_WITH_AT,
  DEFAULTDATETIMEFORMAT,
  GA_EVENT,
  GA_LABEL,
  STAGE_STATUS,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { titleCase } from '../../common/utils';
import CommonPopover from '../../components/CommonPopover';
import CommonTooltip from '../../components/CommonTooltip';
import EllipsisText from '../../components/EllipsisText';

function CommentPopUp({
  previousHistoryData,
  stageItem,
  data,
  onClick,
  eqcStageItemIds,
  setIsBannerBlinking,
}) {
  const [open, setOpen] = useState(false);
  const { eqcStageItemId } = stageItem;
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const [commentData, setCommentData] = useState([]);

  useEffect(() => {
    map(previousHistoryData, (item) => {
      const stageData = filter(
        item?.itemHistory,
        (record) => record?.eqcStageItemId === eqcStageItemId,
      )?.[0];
      if (stageData?.generalRemark) {
        const newData = {
          ...item,
          ...stageData,
        };
        setCommentData((prev) => [newData, ...prev]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStageStatus = (status) => {
    switch (status) {
      case STAGE_STATUS.PASS:
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case STAGE_STATUS.APPROVED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case STAGE_STATUS.ACCEPTED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.ACCEPTED)}</Tag>
        );
      case STAGE_STATUS.APPROVAL_PENDING:
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case STAGE_STATUS.SKIP:
        return <Tag className="yellow-tag">{titleCase(STAGE_STATUS.SKIP)}</Tag>;
      case STAGE_STATUS.REDO:
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;
      case STAGE_STATUS.REJECTED:
        return <Tag color="red">{titleCase(STAGE_STATUS.REJECTED)}</Tag>;
      case STAGE_STATUS.BYPASS:
        return (
          <Tag className="inactive-tag">{titleCase(STAGE_STATUS.BYPASS)}</Tag>
        );
      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };
  return (
    <CommonPopover
      overlayClassName="comment-popup"
      onOpenChange={(e) => {
        if (
          data?.approvalType === APPROVAL_TYPE.ON_SITE &&
          !commentData?.length > 0
        ) {
          setIsBannerBlinking(true);
        } else if (commentData?.length > 0) {
          setOpen(e);
        } else {
          onClick();
        }
      }}
      title={
        <div className="d-flex justify-between align-center">
          <div>Previous Comments</div>
          <CloseIcon
            height={24}
            width={24}
            className="pointer"
            onClick={() => setOpen(false)}
          />
        </div>
      }
      trigger="click"
      placement={isDesktopViewport ? 'left' : 'top'}
      open={open}
      fresh
      content={
        <div>
          {data?.status === STAGE_STATUS.APPROVAL_PENDING && (
            <>
              <div className="d-flex align-center mb-10">
                <div className="level-name mr-10">{data?.levelName}</div>
                {changeStageStatus(data?.status)}
              </div>
              <div className="add-comment-button">
                <Button
                  type="primary"
                  shape="round"
                  icon={<MessageIcon className="mr-5" />}
                  onClick={() => {
                    if (data?.approvalType === APPROVAL_TYPE.ON_SITE) {
                      setIsBannerBlinking(true);
                    } else {
                      setOpen(false);
                      onClick();
                    }
                  }}
                  className={clsx(
                    'width-percent-100 d-flex justify-center align-center',
                    includes(eqcStageItemIds, stageItem?.eqcStageItemId) &&
                      'general-comment',
                    data?.approvalType === APPROVAL_TYPE.ON_SITE &&
                      'ant-btn-default ant-btn-disabled',
                  )}
                >
                  {includes(eqcStageItemIds, stageItem?.eqcStageItemId)
                    ? 'Edit Comment'
                    : 'Add Comment'}
                </Button>
              </div>
              {commentData?.length > 0 && <Divider dashed />}
            </>
          )}
          <div>
            {map(commentData, (item, index) => {
              return (
                item?.generalRemark && (
                  <div key={index}>
                    <div>
                      <div className="d-flex align-center">
                        <div className="level-name mr-10">
                          {item?.levelName}
                        </div>
                        {changeStageStatus(item?.status)}
                      </div>
                      <div className="comment-data-card mt-10">
                        <div className="name-and-time d-flex">
                          <CommonTooltip title={item?.approverName}>
                            <div className="ellipsis-text">
                              {item?.approverName}
                            </div>
                          </CommonTooltip>
                          <Badge
                            status="default"
                            className="ml-5 mr-5"
                            color="#676C77"
                          />
                          {moment(item?.createdAt, DEFAULTDATETIMEFORMAT)
                            ?.tz(data?.project?.timeZone)
                            ?.format(DATE_TIME_WITH_AT)}
                        </div>
                        <div className="comment mt-8">
                          <EllipsisText text={item?.generalRemark} />
                        </div>
                        {item?.generalRemarkImages?.length > 0 && (
                          <Row
                            gutter={18}
                            className="mt-16"
                            align="middle"
                            justify="space-between"
                          >
                            <Col>
                              <Space
                                direction="vertical"
                                size={12}
                                className="mt-12"
                              >
                                <div>
                                  <Image.PreviewGroup
                                    preview={{
                                      visible:
                                        item?.updatedAt === previewImageKey &&
                                        isVisible,
                                      onVisibleChange: (visible) => {
                                        setIsVisible(visible);
                                        setOpen(!visible);
                                        if (visible) {
                                          Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                                            label: GA_LABEL.OPENED_IMAGE_VIEWER,
                                            // eslint-disable-next-line no-undef
                                            pathname: window?.location?.href,
                                          });
                                        }
                                      },
                                    }}
                                  >
                                    <Space className="image-preview-wrapper issue-images">
                                      {React.Children.map(
                                        item?.generalRemarkImages,
                                        (photo) => (
                                          <Image
                                            src={photo}
                                            alt="logo"
                                            height="40px"
                                            width="40px"
                                            onClick={() =>
                                              setPreviewImageKey(
                                                item?.updatedAt,
                                              )
                                            }
                                          />
                                        ),
                                      )}
                                      {item?.generalRemarkImages?.length >
                                        1 && (
                                        <div
                                          className="extra-count pointer"
                                          onClick={() => {
                                            setIsVisible(true);
                                            setOpen(false);
                                            setPreviewImageKey(item?.updatedAt);
                                          }}
                                        >
                                          {`+${
                                            item?.generalRemarkImages?.length -
                                            1
                                          }`}
                                        </div>
                                      )}
                                    </Space>
                                  </Image.PreviewGroup>
                                </div>
                              </Space>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {commentData?.length - 1 > index && <Divider dashed />}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      }
    >
      {(data?.status === STAGE_STATUS.APPROVAL_PENDING ||
        commentData?.length > 0) && (
        <Button
          type="primary"
          shape="round"
          icon={<MessageIcon />}
          onClick={() => {
            if (
              data?.approvalType === APPROVAL_TYPE.ON_SITE &&
              !commentData?.length > 0
            ) {
              setIsBannerBlinking(true);
            } else if (commentData?.length > 0) {
              setOpen(true);
            } else {
              onClick();
            }
          }}
          className={clsx(
            includes(eqcStageItemIds, stageItem?.eqcStageItemId) &&
              'general-comment',
            !isDesktopViewport
              ? 'width-percent-100 justify-center mb-24'
              : 'mb-24',
            data?.approvalType === APPROVAL_TYPE.ON_SITE &&
              !commentData?.length > 0 &&
              'ant-btn-default ant-btn-disabled',
          )}
        >
          <>
            {commentData?.length || ''}
            {commentData?.length > 0 && (
              <Badge color="white" className="mr-5 ml-5" />
            )}
            {
              // eslint-disable-next-line no-nested-ternary
              data?.status === STAGE_STATUS.APPROVAL_PENDING &&
              !commentData?.length > 0
                ? includes(eqcStageItemIds, stageItem?.eqcStageItemId)
                  ? 'Edit Comment'
                  : 'Add Comment'
                : `Comments`
            }
          </>
        </Button>
      )}
    </CommonPopover>
  );
}

export default CommentPopUp;
