import { isNaN } from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Error404 from '../../../Error404';
import { ACCESS_TYPE, ROUTES, TAB_KEYS } from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import HasAccess from '../../../components/HasAccess';

const RedirectToProjectDetails = () => {
  const {
    params: { projectId },
  } = useRouter();
  if (isNaN(Number(projectId))) {
    return <Error404 />;
  }
  const redirectPath = HasAccess({ type: ACCESS_TYPE.INSPECTION })
    ? TAB_KEYS.EQC
    : TAB_KEYS.INSTRUCTION;
  return <Navigate to={`${ROUTES.PROJECTS}/${projectId}/${redirectPath}`} />;
};

export default RedirectToProjectDetails;
