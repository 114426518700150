import { useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import clsx from 'clsx';
import {
  filter,
  forEach,
  includes,
  isBoolean,
  keys,
  map,
  omit,
  toLower,
} from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../../AppContext';
import { toast } from '../../../../../../../../apollo';
import { DeleteButton } from '../../../../../../../../assets/svg';
import {
  ADDONS,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  NUMERIC_LOGIC_OPERATORS,
  PROJECT_CHECKLIST_STATUS,
  REGEX,
  TYPES,
  TYPES_LABELS,
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../../../common/utils';
import CommonPopover from '../../../../../../../../components/CommonPopover';
import CommonSelect from '../../../../../../../../components/CommonSelect';
import TextEditor from '../../../../../../../../components/TextEditor';
import {
  CREATE_PROJECT_EQC_STAGE_ITEM,
  UPDATE_PROJECT_EQC_STAGE_ITEM,
} from '../../../../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const YesNoUi = (isDesktopViewport, isDisabled) => {
  return (
    <>
      <Row gutter={20}>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item label="Value">
            <Input allowClear placeholder="Yes" disabled />
          </Form.Item>
        </Col>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item name="yesOption" label=" " valuePropName="checked">
            <Checkbox disabled={isDisabled}>Mark for QC fail</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item label="Value">
            <Input allowClear placeholder="No" disabled defaultValue="No" />
          </Form.Item>
        </Col>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item name="noOption" label=" " valuePropName="checked">
            <Checkbox checked disabled={isDisabled}>
              Mark for QC fail
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const NumericLogicUi = (isDesktopViewport, isDisabled) => {
  return (
    <>
      <Row className="mb-10">
        <Col
          span={isDesktopViewport ? 13 : 24}
          className={clsx(
            'd-flex align-center',
            !isDesktopViewport && 'justify-center mb-5',
          )}
        >
          If value is
          <Form.Item
            name="operator"
            className="ml-5 mb-0 numeric-input"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    (getFieldValue('value') ||
                      isBoolean(getFieldValue('qcFail'))) &&
                    !value
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('This field is required!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <CommonSelect
              placeholder="select"
              disabled={isDisabled}
              options={[
                ...map(NUMERIC_LOGIC_OPERATORS, (value) => {
                  return {
                    key: value,
                    value,
                    label: value,
                  };
                }),
              ]}
            />
          </Form.Item>
          <Form.Item
            name="value"
            className="value-input"
            rules={[
              {
                pattern: REGEX.POSITIVE_DECIMAL_NUMBER,
                message: 'Enter valid number',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    (getFieldValue('operator') ||
                      isBoolean(getFieldValue('qcFail'))) &&
                    !value
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('This field is required!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              allowClear
              disabled={isDisabled}
              placeholder="Enter Number"
            />
          </Form.Item>
          then,
        </Col>
        <Col
          span={isDesktopViewport ? 11 : 24}
          className={clsx(
            'd-flex align-center',
            !isDesktopViewport && 'justify-center mb-5',
          )}
        >
          <Form.Item
            name="qcFail"
            className="mb-0 numeric-input"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    (getFieldValue('operator') || getFieldValue('value')) &&
                    !isBoolean(value)
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('This field is required!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Radio.Group disabled={isDisabled}>
              <Radio value>Mark for QC fail</Radio>
              <Radio value={false}>Mark for QC pass</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const OptionUi = (isDesktopViewport, isDisabled, selectedStageData) => {
  return (
    <>
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <Row gutter={[15, 15]} className="width-percent-100">
                {fields.map((field) => (
                  <Col span={24} key={field.key}>
                    <Row gutter={[30]}>
                      <Col flex="auto">
                        <Form.Item
                          {...field}
                          label="Value"
                          name={[field.name, 'option']}
                          fieldKey={[field.fieldKey, 'option']}
                          validateTrigger={['onChange', 'onClick']}
                          rules={[
                            required,
                            characterWithoutWhiteSpace,
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                const items = map(
                                  getFieldValue()?.options,
                                  (item) => item?.option,
                                );
                                if (
                                  filter(
                                    items,
                                    (val) => toLower(val) === toLower(value),
                                  )?.length > 1 &&
                                  value
                                ) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'should be a unique option',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            placeholder="Enter  Value"
                            disabled={isDisabled}
                          />
                        </Form.Item>
                      </Col>
                      {selectedStageData?.type !== ADDONS.RFI && (
                        <Col className="d-flex align-center">
                          <Form.Item
                            {...field}
                            label={isDesktopViewport && ' '}
                            name={[field.name, 'qcFail']}
                            fieldKey={[field.fieldKey, 'qcFail']}
                            valuePropName="checked"
                            className="mr-10 mb-0"
                          >
                            <Checkbox disabled={isDisabled}>
                              Mark for QC fail
                            </Checkbox>
                          </Form.Item>
                          {fields?.length > 2 && (
                            <Form.Item
                              label={isDesktopViewport && ' '}
                              className="mb-0"
                            >
                              <DeleteButton
                                className="pointer"
                                onClick={() => {
                                  if (!isDisabled && fields?.length > 2)
                                    remove(field?.name);
                                }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                      )}
                    </Row>
                  </Col>
                ))}

                <Row>
                  <Col className="mb-15">
                    <Button
                      className="grey-button"
                      shape="round"
                      disabled={isDisabled}
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      Add Option
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

const AddEditModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    stageData,
    setStageData,
    stageId,
    refetchStageDataWithInitialValues,
    isDisabled,
    isEditable,
    eqcTypeData,
    selectedStageData,
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [displayOption, setDisplayOption] = useState(
    selectedStageData?.type !== ADDONS.RFI,
  );
  const [imageLoader, setImageLoader] = useState(false);
  const [isYesNoType, setIsYesNoType] = useState(true);
  const [isNumeric, setIsNumeric] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [displayOptionValue, setDisplayOptionValue] = useState(
    selectedStageData?.type !== ADDONS.RFI && YesNoUi(isDesktopViewport),
  );
  const {
    params: { projectId },
  } = useRouter();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };
  const toggleImageLoader = () => {
    setImageLoader((value) => !value);
  };
  const [createStage, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_EQC_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.ADD_PROJECT_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      },
    },
  );

  const [updateStage, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_EQC_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.EDIT_PROJECT_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          stage_item_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      },
    },
  );

  const handleTypeChange = (options) => {
    switch (options?.value) {
      case TYPES['Y/N']:
        if (selectedStageData?.type !== ADDONS.RFI) {
          setDisplayOption(true);
          setIsYesNoType(true);
          setIsNumeric(false);
          setDisplayOptionValue(
            YesNoUi(
              isDesktopViewport,
              isDisabled ||
                !isEditable ||
                (includes(
                  [
                    PROJECT_CHECKLIST_STATUS.PUBLISHED,
                    PROJECT_CHECKLIST_STATUS.ARCHIVED,
                  ],
                  eqcTypeData?.projectEqcType?.status,
                ) &&
                  isUpdate),
            ),
          );
        }
        break;
      case TYPES.OPTION:
        setDisplayOption(true);
        setIsYesNoType(false);
        setIsNumeric(false);
        setDisplayOptionValue(
          OptionUi(
            isDesktopViewport,
            isDisabled ||
              !isEditable ||
              (includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED,
                ],
                eqcTypeData?.projectEqcType?.status,
              ) &&
                isUpdate),
            selectedStageData,
          ),
        );
        break;
      case TYPES.NUMERIC:
        if (selectedStageData?.type !== ADDONS.RFI) {
          setDisplayOption(true);
          setIsYesNoType(false);
          setIsNumeric(true);
          setDisplayOptionValue(
            NumericLogicUi(
              isDesktopViewport,
              isDisabled ||
                !isEditable ||
                (includes(
                  [
                    PROJECT_CHECKLIST_STATUS.PUBLISHED,
                    PROJECT_CHECKLIST_STATUS.ARCHIVED,
                  ],
                  eqcTypeData?.projectEqcType?.status,
                ) &&
                  isUpdate),
            ),
          );
        }
        break;
      default:
        setDisplayOption(false);
        setIsYesNoType(false);
        setIsNumeric(false);
        break;
    }
  };
  const handleSelect = (labeledValue, options) => {
    handleTypeChange(options);
    if (labeledValue === TYPES.OPTION) {
      form.setFieldsValue({ options: [{}, { qcFail: true }] });
    } else if (labeledValue === TYPES['Y/N']) {
      form.setFieldsValue({ noOption: true });
    }
  };
  useEffect(() => {
    if (isUpdate) {
      handleTypeChange({ value: stageData?.type });
      if (stageData?.type === 'Y/N') {
        form.setFieldsValue({ yesOption: stageData?.options?.[0]?.qcFail });
        form.setFieldsValue({ noOption: stageData?.options?.[1]?.qcFail });
      }
      if (stageData?.type === TYPES?.NUMERIC) {
        form.setFieldsValue({
          operator: stageData?.options?.[0]?.operator,
          qcFail: stageData?.options?.[0]?.qcFail,
          value: stageData?.options?.[0]?.threshold,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  const onAgencySubmitFinish = async (formValues) => {
    if (
      formValues?.description?.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
      !formValues?.description?.includes('<img')
    ) {
      form.setFieldsValue('description', '');
    }
    let options = [];
    if (formValues.type === TYPES['Y/N']) {
      options = [
        {
          option: 'Yes',
          qcFail: formValues.yesOption,
        },
        {
          option: 'No',
          qcFail: formValues.noOption,
        },
      ];
    }
    if (formValues.type === TYPES?.NUMERIC && formValues.operator) {
      options = [
        {
          operator: formValues.operator,
          qcFail: formValues.qcFail,
          threshold: formValues.value,
        },
      ];
    }
    if (formValues.type === TYPES.OPTION) {
      forEach(formValues.options, (option) => {
        const typeOptionValue = {
          option: option.option,
          qcFail: option.qcFail || false,
        };
        options.push(typeOptionValue);
      });
      const checkCheckedValue = (value) => {
        return value.qcFail === false;
      };
      const isValid = options.some(checkCheckedValue);
      if (!isValid) {
        toast({
          message: 'At least one option should be marked as QcPass',
          type: 'error',
        });
        return;
      }
    }

    let newFormValues = {
      title: formValues.title,
      description:
        formValues?.description?.replace(/<(.|\n)*?>/g, '').trim().length ===
          0 && !formValues?.description?.includes('<img')
          ? ''
          : formValues?.description,
      type: formValues?.type,
      photo: formValues?.photo || false,
      remark: formValues?.remark || false,
      options,
      projectEqcTypeStageId: Number(stageId),
    };
    if (isDisabled) {
      newFormValues = omit(newFormValues, ['title', 'type', 'options']);
    }
    const variables = isUpdate
      ? { data: newFormValues, id: stageData.id }
      : newFormValues;

    try {
      if (isUpdate) {
        await updateStage({
          variables,
        });
        return;
      }
      await createStage({
        variables: {
          data: variables,
        },
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (showModal && isUpdate) {
      form.setFieldsValue({
        ...stageData,
        description: stageData?.description || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="stageitem-modal"
      footer={null}
      closable={false}
      width={isDesktopViewport ? 700 : '90vw'}
    >
      <h2 className="mb-15">
        {isUpdate ? 'Edit Stage Item' : 'Add Stage Item'}
      </h2>
      {includes(
        [PROJECT_CHECKLIST_STATUS.PUBLISHED, PROJECT_CHECKLIST_STATUS.ARCHIVED],
        eqcTypeData?.projectEqcType?.status,
      ) &&
        isUpdate && (
          <CommonPopover
            overlayClassName="checklist-item-note-popup"
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.stageitem-modal')
            }
            trigger={isDesktopViewport ? 'hover' : 'click'}
            placement={isDesktopViewport ? 'right' : 'bottom'}
            destroyTooltipOnHide
            content={
              <div onClick={(e) => e.stopPropagation()}>
                Changing checklist question, type and options will affect the
                analysis of the data and will give you improper information.
                Decision making through this data will not be proper.
              </div>
            }
          >
            <div className="note">
              Why can't I edit question, type and options?
            </div>
          </CommonPopover>
        )}
      <Form
        form={form}
        initialValues={{
          ...(!isUpdate && {
            type: 'Y/N',
            noOption: true,
            options: [{}, { qcFail: true }],
          }),
        }}
        className={
          (!includes(
            [
              PROJECT_CHECKLIST_STATUS.PUBLISHED,
              PROJECT_CHECKLIST_STATUS.ARCHIVED,
            ],
            eqcTypeData?.projectEqcType?.status,
          ) ||
            !isUpdate) &&
          'mt-16'
        }
        layout="vertical"
        onFinish={onAgencySubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 500,
              message: 'Title cannot be more than 500 characters',
            },
          ]}
          name="title"
          label="Check Point"
        >
          <Input
            allowClear
            placeholder="Enter Checklist Point"
            disabled={
              isDisabled ||
              !isEditable ||
              (includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED,
                ],
                eqcTypeData?.projectEqcType?.status,
              ) &&
                isUpdate)
            }
          />
        </Form.Item>
        <Form.Item name="description" label="Information">
          <TextEditor
            readOnly={isDisabled || !isEditable}
            toggleImageLoader={toggleImageLoader}
          />
        </Form.Item>
        <Row gutter={30}>
          <Col span={isDesktopViewport ? 12 : 24}>
            <Form.Item name="type" label="Type">
              <CommonSelect
                className="mr-3"
                placeholder="Select Type"
                onSelect={handleSelect}
                disabled={
                  isDisabled ||
                  !isEditable ||
                  (includes(
                    [
                      PROJECT_CHECKLIST_STATUS.PUBLISHED,
                      PROJECT_CHECKLIST_STATUS.ARCHIVED,
                    ],
                    eqcTypeData?.projectEqcType?.status,
                  ) &&
                    isUpdate)
                }
                options={[
                  ...keys(TYPES_LABELS).map((type) => {
                    return {
                      key: TYPES_LABELS[type],
                      value: type,
                      label: TYPES_LABELS[type],
                    };
                  }),
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 6 : 12}>
            <Form.Item
              name="photo"
              label="EQC Photo Required"
              valuePropName="checked"
            >
              <Switch
                disabled={isDisabled || !isEditable}
                defaultChecked={isUpdate && stageData?.photo}
              />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 6 : 12}>
            <Form.Item
              name="remark"
              label="Remarks Required"
              valuePropName="checked"
            >
              <Switch
                disabled={isDisabled || !isEditable}
                defaultChecked={isUpdate && stageData?.remark}
              />
            </Form.Item>
          </Col>
        </Row>
        {displayOption && (
          <>
            <Divider dashed />
            {!isNumeric && <h3>{isYesNoType ? 'Yes/No' : 'Options'}</h3>}
            {displayOptionValue}
          </>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            hidden={isDisabled || !isEditable}
            disabled={imageLoader}
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditModal;
