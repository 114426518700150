import { Button, Modal } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { DEFAULTDATETIMEFORMAT, STAGE_STATUS } from '../../common/constants';

const ApprovalPopUp = (props) => {
  const { showModal, setShowModal, data } = props;

  const handleOK = () => {
    setShowModal(false);
  };

  const stageDetail = data?.getEqcStageHistoryWithoutAuth;
  const approverName =
    stageDetail?.reviewedBy?.externalApprover?.name ||
    stageDetail?.reviewedBy?.internalApprover?.user?.name;
  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      closable={false}
      footer={null}
    >
      <h3>EQC already reviewed</h3>
      <div>
        <p className="font-size-16 mb-5">
          {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
            ? `${approverName} already ${
                stageDetail && STAGE_STATUS[stageDetail?.status]
              } this RFI on date ${
                stageDetail &&
                moment(stageDetail?.updatedAt)
                  ?.tz(data?.getEqcStageHistoryWithoutAuth?.project?.timeZone)
                  ?.format(DEFAULTDATETIMEFORMAT)
                  ?.toUpperCase()
              }.`
            : `${approverName} has already done the review and given ${
                stageDetail && STAGE_STATUS[stageDetail?.status]
              } to inspector ${
                stageDetail && stageDetail?.action && stageDetail?.action?.name
              }  on date ${
                stageDetail &&
                moment(stageDetail?.updatedAt)
                  ?.tz(data?.getEqcStageHistoryWithoutAuth?.project?.timeZone)
                  ?.format(DEFAULTDATETIMEFORMAT)
                  ?.toUpperCase()
              }.`}
        </p>
      </div>
      <div className="form-buttons">
        <Button
          shape="round"
          type="primary"
          className="save-button"
          onClick={handleOK}
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export default ApprovalPopUp;
