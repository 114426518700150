import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from 'antd';
import clsx from 'clsx';
import { get, includes, omit, trim, uniq } from 'lodash';
import momentTz from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { ForwardArrow, ProjectIcon } from '../../../assets/svg';
import {
  ADDONS,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PERMISSIONS,
  REGEX,
  REQUEST_FEATURE_UPGRADE_KEYS,
  ROUTES,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import useRouter from '../../../common/useRouter';
import { formValidatorRules } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import EllipsisText from '../../../components/EllipsisText';
import GoogleMapComponent from '../../../components/GoogleMap';
import UpgradeModal from '../../../components/UpgradeModal';
import { UPDATE_PROJECT } from '../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../graphql/Queries';
import DeactivateRfiModal from './DeactivateRfiModal';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const timeZones = momentTz.tz.names();
const timezone = uniq(timeZones)?.map((timeZone, index) => ({
  key: index,
  value: timeZone,
  label: `${timeZone} (GMT${momentTz?.tz(timeZone)?.format('Z')})`,
}));
const EditProjectDetails = (props) => {
  const { showModal, callback } = props;
  const {
    navigate,
    params: { projectId },
  } = useRouter();

  const { getCurrentUser, getTenantUser, dispatch } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const currentTenant = getTenantUser();
  const [form] = Form.useForm();
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDeactivateRfiModal, setShowDeactivateRfiModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchProjectDetails, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject),
        });
        const formData = {
          ...res?.getProject,
          radius: String(res?.getProject?.radius),
        };
        if (res?.getProject?.permissions?.includes(PERMISSIONS.FINGERPRINT)) {
          formData.fingerprint = true;
        }
        if (res?.getProject?.permissions?.includes('LOCATION')) {
          formData.location = true;
        }
        form.setFieldsValue(formData);
        setMarker({
          lat: res?.getProject?.latitude,
          lng: res?.getProject?.longitude,
        });
        setLoading(false);
        setRadius(Number(res?.getProject?.radius));
      },
      onError() {
        setLoading(false);
      },
    },
  );

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_PROJECT, {
        label: GA_LABEL.EDIT_PROJECT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
    },
  });
  const handleCancel = () => {
    setInputValue('');
    form.resetFields();
    navigate(-1);
  };
  const submitForm = async (values) => {
    if (!marker) {
      message.destroy();
      message.error('Enter valid address in site details');
      return;
    }
    const { location, fingerprint } = values;
    const permissions = [];
    if (location) {
      permissions.push(PERMISSIONS.LOCATION);
    }
    if (fingerprint) {
      permissions.push(PERMISSIONS.FINGERPRINT);
    }
    const data = omit(
      { ...values, latitude: marker?.lat, longitude: marker?.lng, permissions },
      ['location', 'fingerprint', 'logo'],
    );
    data.radius = Number(data?.radius);
    await updateProject({
      variables: { data, id: Number(projectId) },
    });
    handleCancel();
    if (callback) {
      callback({
        variables: {
          id: Number(projectId),
        },
      });
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchProjectDetails({ variables: { id: Number(projectId) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, showModal]);

  useEffect(() => {
    form.setFieldsValue({ latitude: marker?.lat, longitude: marker?.lng });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);

  const items = [
    {
      key: '1',
      label: 'Latitude',
      children: marker?.lat || '-',
      span: 2,
    },
    {
      key: '2',
      label: 'Longitude',
      children: marker?.lng || '-',
      span: 2,
    },
  ];

  return (
    <>
      <div>
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex">
            <ProjectIcon
              onClick={() => {
                navigate(ROUTES.PROJECTS);
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1
              className="pointer mr-10 text-primary"
              onClick={() => {
                navigate(`${ROUTES.PROJECTS}/${projectId}`);
              }}
            >
              {projectData?.getProject?.name}
            </h1>
            <ForwardArrow />
            <EllipsisText className="h1-typography" text="Edit Project" />
          </div>
        </div>
        <Form form={form} layout="vertical" onFinish={submitForm}>
          <Row gutter={24}>
            <Col
              xs={24}
              lg={12}
              className={clsx(!isDesktopViewport && 'mb-10')}
            >
              <CommonCard loading={loading}>
                <h3>Project Details</h3>
                <Form.Item
                  rules={[
                    required,
                    characterWithoutWhiteSpace,
                    { max: 50, message: 'Maximum 50 characters allowed' },
                  ]}
                  name="name"
                  label="Name"
                >
                  <Input allowClear placeholder="Enter Name" />
                </Form.Item>
                <Form.Item
                  rules={[
                    required,
                    characterWithoutWhiteSpace,
                    { max: 20, message: 'Maximum 20 characters allowed' },
                  ]}
                  name="uniqueCode"
                  label="Unique Code"
                >
                  <Input allowClear placeholder="Enter Project Code" />
                </Form.Item>
                <Form.Item
                  rules={[
                    required,
                    characterWithoutWhiteSpace,
                    { max: 50, message: 'Maximum 50 characters allowed' },
                  ]}
                  name="clientName"
                  label="Client Name"
                >
                  <Input allowClear placeholder="Enter Client Name" />
                </Form.Item>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label="Description"
                      rules={[
                        {
                          max: 1000,
                          message:
                            'Description cannot be more than 1000 characters',
                        },
                      ]}
                    >
                      <Input.TextArea
                        allowClear
                        autoSize={{ minRows: 3, maxRows: 6 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <div className="mb-10 text-secondary">Permissions</div>
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-center mr-20">
                      <div className="mr-16">Location:</div>
                      <Form.Item
                        name="location"
                        className="m-0"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="d-flex align-center mr-20">
                        <div className="mr-16">Authentication:</div>
                        <Form.Item
                          name="fingerprint"
                          className="m-0"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="d-flex align-center">
                      <div className="mr-5">RFI:</div>
                      <Form.Item
                        name="isRfiActive"
                        className="m-0"
                        valuePropName="checked"
                      >
                        <Switch
                          onChange={(e) => {
                            if (
                              e &&
                              !includes(
                                currentTenant?.tenant?.addOn,
                                ADDONS.RFI,
                              )
                            ) {
                              setShowUpgradeModal(true);
                              form.setFieldsValue({ isRfiActive: false });
                            } else if (!e) {
                              setShowDeactivateRfiModal(true);
                              form.setFieldsValue({ isRfiActive: true });
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </CommonCard>
            </Col>
            <Col xs={24} lg={12}>
              <CommonCard loading={loading}>
                <h3>Site Details</h3>
                <Row gutter={[18]}>
                  <Col span={isDesktopViewport ? 8 : 24}>
                    <Form.Item
                      rules={[
                        required,
                        {
                          pattern: REGEX.NUMBER,
                          message: 'Enter a valid number',
                        },
                        {
                          max: 7,
                          message: 'Value cannot be more than 7 characters',
                        },
                      ]}
                      name="radius"
                      label="Radius (m)"
                    >
                      <Input
                        allowClear
                        placeholder="Enter Radius"
                        onChange={(e) => setRadius(Number(e?.target?.value))}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={isDesktopViewport ? 16 : 24}>
                    <Form.Item
                      name="timeZone"
                      label="TimeZone"
                      rules={[required]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Timezone"
                        getPopupContainer={(triggerNode) =>
                          triggerNode?.parentElement
                        }
                        filterOption={(input, option) => {
                          const label = get(option, 'label', '');
                          return label
                            ?.toLowerCase()
                            ?.includes(trim(input?.toLowerCase()));
                        }}
                        options={timezone}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <GoogleMapComponent
                  marker={marker}
                  setMarker={setMarker}
                  radius={radius}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
                <Descriptions layout="vertical" items={items} />
              </CommonCard>
            </Col>
          </Row>
          <Row justify="end" className="mt-10">
            <div className="form-buttons">
              <Button shape="round" onClick={handleCancel}>
                Cancel
              </Button>
              <Button shape="round" type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.RFI}
          isAlreadyRequested={projectData?.getProject?.isRfiRequestSent}
          refetch={() => {
            fetchProjectDetails({ variables: { id: Number(projectId) } });
          }}
        />
      )}
      {showDeactivateRfiModal && (
        <DeactivateRfiModal
          showModal={showDeactivateRfiModal}
          setShowModal={setShowDeactivateRfiModal}
          handleOk={() => {
            setShowDeactivateRfiModal(false);
            form.setFieldsValue({ isRfiActive: false });
          }}
          handleCancel={() => {
            setShowDeactivateRfiModal(false);
            form.setFieldsValue({ isRfiActive: true });
          }}
        />
      )}
    </>
  );
};

export default EditProjectDetails;
