import { Tabs, Tag } from 'antd';
import { filter, includes, values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import Error404 from '../../Error404';
import { InfoIconDark } from '../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  LOGS_TABS_KEYS,
  ROUTES,
  SYSTEM_ROLES,
} from '../../common/constants';
import useRouter from '../../common/useRouter';
import CanPerform from '../../components/CanPerform';
import CommonPopover from '../../components/CommonPopover';
import HasAccess from '../../components/HasAccess';
import Activity from './pages/Activity';
import Gallery from './pages/Gallery';
import Instruction from './pages/Instructions';

const Logs = () => {
  const {
    navigate,
    params: { tab },
  } = useRouter();
  const [activeKey, setActiveKey] = useState(LOGS_TABS_KEYS.ACTIVITY);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const {
    state: {
      currentUser: { projectUsers },
    },
  } = useContext(AppContext);
  const isProjectAdmin = !!filter(
    projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN,
  ).length;

  useEffect(() => {
    if (tab) {
      setActiveKey(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!includes(values(LOGS_TABS_KEYS), tab)) {
    return <Error404 />;
  }
  const items = [
    ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
      ? [
          {
            key: LOGS_TABS_KEYS.ACTIVITY,
            label: 'Eqc Logs',
            children: (
              <div className="details-tab">
                {' '}
                <div className="activity">
                  <Activity />
                </div>{' '}
              </div>
            ),
          },
        ]
      : []),
    ...(HasAccess({ type: ACCESS_TYPE.INSTRUCTION })
      ? [
          {
            key: LOGS_TABS_KEYS.INSTRUCTION,
            label: 'Issue Logs',
            children: (
              <div className="instruction">
                <Instruction />
              </div>
            ),
          },
        ]
      : []),
    ...(CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_GALLERY,
    }) || isProjectAdmin
      ? [
          {
            key: LOGS_TABS_KEYS.GALLERY,
            label: (
              <div className="d-flex align-center">
                Gallery
                <Tag className="tag-beta ml-5" color="#202A3F">
                  BETA
                </Tag>
                <CommonPopover
                  placement={isDesktopViewport ? 'right' : 'bottom'}
                  content={
                    <>
                      This is beta feature and may have bug and errors. This
                      feature can be stopped without any prior notice.
                    </>
                  }
                  overlayClassName="gallery-info"
                  trigger={[!isDesktopViewport ? 'click' : 'hover']}
                >
                  <InfoIconDark height="18px" width="18px" />
                </CommonPopover>
              </div>
            ),
            children: (
              <div className="instruction">
                <Gallery />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Activity Details</div>
        </>
      )}
      <div className="logs">
        <Tabs
          activeKey={activeKey}
          destroyInactiveTabPane
          onChange={(key) => {
            setActiveKey(key);
            navigate(`${ROUTES.LOGS}/${key}`);
          }}
          items={items}
        />
      </div>
    </>
  );
};

export default Logs;
