import { Card, Col, Divider, Image, Row, Space, Tag } from 'antd';
import { drop, forEach, groupBy, map, slice } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import { CloseIcon, InfoIconDark } from '../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DATE_TIME_WITH_AT,
  GA_EVENT,
  GA_LABEL,
  STAGE_STATUS,
  STAGE_STATUS_KEYS,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { timeTaken, titleCase } from '../../common/utils';
import CommonPopover from '../../components/CommonPopover';
import CommonTooltip from '../../components/CommonTooltip';
import EllipsisText from '../../components/EllipsisText';
import CommentModal from './CommentModal';
import CommentSection from './CommentSection';

const StageSummary = ({
  data,
  refetch,
  commentData,
  setCommentData,
  loading,
  open,
  setOpen,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [previewImageKey, setPreviewImageKey] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showModal, setShowModal] = useState();
  const approversByLevel = groupBy(
    data?.getEqcStageHistoryWithoutAuth?.projectEqcTypeStage
      ?.projectEqcTypeStageApprovals,
    (b) => {
      return b?.levelNumber;
    },
  );
  const levelsToShowAllApprovers = drop(
    Object.keys(approversByLevel),
    data?.getEqcStageHistoryWithoutAuth?.status ===
      STAGE_STATUS_KEYS?.APPROVAL_PENDING
      ? data?.getEqcStageHistoryWithoutAuth?.levelNumber - 1
      : data?.getEqcStageHistoryWithoutAuth?.levelNumber,
  );
  const stageStatus = (status) => {
    switch (status) {
      case STAGE_STATUS_KEYS.PASS:
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case STAGE_STATUS_KEYS.APPROVED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case STAGE_STATUS_KEYS.ACCEPTED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.ACCEPTED)}</Tag>
        );
      case STAGE_STATUS_KEYS.APPROVAL_PENDING:
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case STAGE_STATUS_KEYS.REDO:
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;
      case STAGE_STATUS_KEYS.REJECTED:
        return <Tag color="red">{titleCase(STAGE_STATUS.REJECTED)}</Tag>;
      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };

  const getPhotos = (photos, imageId) => {
    return photos?.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
              });
            }
          },
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      '-'
    );
  };
  const getLevelWiseApproversFromPreviousHistory = () => {
    return map(
      data?.getEqcStageHistoryWithoutAuth?.previousHistoryData,
      (record, index) => {
        return (
          <>
            <Card className="approvers-card">
              <Row justify="space-between" gutter={18}>
                <Col span={14}>
                  <EllipsisText
                    className="text-secondary mb-15 level-name"
                    text={record?.levelName}
                  />
                  {record?.eqcApprovalLogs?.length > 0 ? (
                    <div>
                      By
                      <Tag className="ml-5 approver-name-tag">
                        {record?.eqcApprovalLogs?.[0]?.externalApprover?.name ||
                          record?.eqcApprovalLogs?.[0]?.internalApprover?.user
                            ?.name}
                      </Tag>
                    </div>
                  ) : (
                    '-'
                  )}
                </Col>
                <Col span={10}>
                  <div className="mb-15">{stageStatus(record?.status)}</div>
                  <div className="font-size-12">
                    {moment(record?.updatedAt)
                      ?.tz(
                        data?.getEqcStageHistoryWithoutAuth?.project?.timeZone,
                      )
                      ?.format(DATE_TIME_WITH_AT)}
                  </div>
                </Col>
              </Row>
            </Card>
            {(data?.getEqcStageHistoryWithoutAuth?.previousHistoryData?.length -
              1 >
              index ||
              levelsToShowAllApprovers?.length > 0) && (
              <Divider className="approver-divider" dashed />
            )}
          </>
        );
      },
    );
  };

  const getAllApproversDataByLevel = (level, index) => {
    const fetchName = [];
    if (approversByLevel?.[level]?.length > 0) {
      forEach(approversByLevel?.[level], (approver) => {
        const { externalApprover, internalApprover } = approver;
        fetchName.push(externalApprover?.name || internalApprover?.user?.name);
      });
    }
    return (
      <>
        <Card className="approvers-card">
          <Row justify="space-between" gutter={18}>
            <Col span={14}>
              <EllipsisText
                className="text-secondary mb-15 level-name"
                text={approversByLevel?.[level]?.[0]?.levelName || '-'}
              />
            </Col>
            <Col span={10}>
              <div className="mb-15">
                {stageStatus(STAGE_STATUS_KEYS.APPROVAL_PENDING)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                from
                {fetchName?.length > 0 && (
                  <Tag className="ml-5 approver-name-tag">{fetchName?.[0]}</Tag>
                )}
                {fetchName?.length > 1 && (
                  <CommonTooltip
                    title={slice(fetchName, 1, fetchName?.length).join(', ')}
                    placement={isDesktopViewport ? 'right' : 'top'}
                    getPopupContainer={() =>
                      // eslint-disable-next-line no-undef
                      document.querySelector('.approval-link')
                    }
                  >
                    {`and +${fetchName?.length - 1}`}
                  </CommonTooltip>
                )}
              </div>
            </Col>
          </Row>
        </Card>
        {levelsToShowAllApprovers?.length - 1 > index && (
          <Divider className="approver-divider" dashed />
        )}
      </>
    );
  };
  return (
    <>
      {showModal && (
        <CommentModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
          refetch={refetch}
          setCommentData={setCommentData}
          commentData={commentData}
        />
      )}
      <Card className="eqc-general-details" loading={loading}>
        <div className="d-flex align-center justify-between mb-10">
          <h4>{`Current EQC Stage: ${data?.getEqcStageHistoryWithoutAuth?.name} (${data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcStageIndex}/${data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcStageCount})`}</h4>
        </div>
        <Row
          className="mb-15"
          gutter={isDesktopViewport ? 32 : [16, 32]}
          justify="space-between"
          wrap={!isDesktopViewport}
        >
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">
                {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                  ? 'Requested '
                  : 'Inspected '}
                By
              </div>
              <EllipsisText
                text={data?.getEqcStageHistoryWithoutAuth?.action?.name}
              />
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Completed On</div>
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt
                  ? moment(
                      data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt,
                    )
                      ?.tz(
                        data?.getEqcStageHistoryWithoutAuth?.project?.timeZone,
                      )
                      ?.format(DATETIMEWITHDIVIDE)
                  : '-'}
              </div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Synced On</div>
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.eqcStageSynceStartedAt
                  ? moment(
                      data?.getEqcStageHistoryWithoutAuth
                        ?.eqcStageSynceCompletedAt,
                    )
                      ?.tz(
                        data?.getEqcStageHistoryWithoutAuth?.project?.timeZone,
                      )
                      ?.format(DATETIMEWITHDIVIDE)
                  : '-'}
              </div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium d-flex">
                Status
                <CommonPopover
                  overlayClassName="approver-info stage-summary"
                  onOpenChange={setOpen}
                  autoAdjustOverflow={!isDesktopViewport}
                  title={
                    <Row
                      onClick={(e) => e.stopPropagation()}
                      justify="space-between"
                      align="top"
                    >
                      <Col span={20}>
                        <EllipsisText
                          text={
                            data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcName
                          }
                        />
                      </Col>
                      <Col span={2}>
                        <CloseIcon
                          height={24}
                          width={24}
                          className="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                          }}
                        />
                      </Col>
                    </Row>
                  }
                  trigger="click"
                  placement={isDesktopViewport ? 'left' : 'top'}
                  destroyTooltipOnHide
                  open={open}
                  content={
                    <div onClick={(e) => e.stopPropagation()}>
                      {getLevelWiseApproversFromPreviousHistory()}
                      {map(levelsToShowAllApprovers, (level, index) => {
                        return getAllApproversDataByLevel(level, index);
                      })}
                    </div>
                  }
                >
                  <InfoIconDark
                    height={18}
                    width={18}
                    className="approver-info-icon ml-5"
                  />
                </CommonPopover>
              </div>
              {stageStatus(data?.getEqcStageHistoryWithoutAuth?.status)}
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">
                Drawing Title Photos
              </div>
              {data?.getEqcStageHistoryWithoutAuth?.drawingTitleImg?.length ===
                0 ||
              data?.getEqcStageHistoryWithoutAuth?.drawingTitleImg === null ? (
                '-'
              ) : (
                <>
                  {getPhotos(
                    data?.getEqcStageHistoryWithoutAuth?.drawingTitleImg,
                    'drawingTitleImg',
                  )}
                </>
              )}
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Drawing Photos</div>
              {data?.getEqcStageHistoryWithoutAuth?.drawingImg?.length === 0 ||
              data?.getEqcStageHistoryWithoutAuth?.drawingImg === null ? (
                '-'
              ) : (
                <>
                  {getPhotos(
                    data?.getEqcStageHistoryWithoutAuth?.drawingImg,
                    'drawingImg',
                  )}
                </>
              )}
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.drawingPDF?.length >
                  0 && (
                  <a
                    href={data?.getEqcStageHistoryWithoutAuth?.drawingPDF?.[0]}
                    target="_blank"
                    className="text-muted"
                    // eslint-disable-next-line react/no-invalid-html-attribute
                    rel="noreferrer nooppener"
                    onClick={() => {
                      Event(GA_EVENT.VIEW_DRAWING_PDF, {
                        label: GA_LABEL.VIEW_DRAWING_PDF,
                        // eslint-disable-next-line no-undef
                        pathname: window?.location?.href,
                        project_id:
                          data?.getEqcStageHistoryWithoutAuth?.projectId,
                        project_eqc_id:
                          data?.getEqcStageHistoryWithoutAuth?.eqc?.id,
                        project_eqc_stage_id:
                          data?.getEqcStageHistoryWithoutAuth?.eqcStage?.id,
                        user_id: currentUser?.id,
                        user_name: currentUser?.name,
                        tenant_id: currentUser?.tenantUser?.tenant?.id,
                        tenant_name:
                          currentUser?.tenantUser?.tenant?.organizationName,
                      });
                    }}
                  >
                    View Drawing
                  </a>
                )}
              </div>
            </Space>
          </Col>
        </Row>
        <Row
          className="mb-15"
          gutter={isDesktopViewport ? 32 : [16, 32]}
          justify="space-between"
          wrap={!isDesktopViewport}
        >
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Witnesses</div>
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.witnessesId
                  ?.map((witnessesId) => witnessesId)
                  .join(', ')}
              </div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Time Taken</div>
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.eqcStageStartedAt &&
                data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt
                  ? timeTaken(
                      data?.getEqcStageHistoryWithoutAuth?.eqcStageStartedAt,
                      data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt,
                    )
                  : '-'}
              </div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Witness Photos</div>
              {data?.getEqcStageHistoryWithoutAuth?.witnessesImg?.length ===
                0 ||
              data?.getEqcStageHistoryWithoutAuth?.witnessesImg === null ? (
                '-'
              ) : (
                <>
                  {getPhotos(
                    data?.getEqcStageHistoryWithoutAuth?.witnessesImg,
                    'witnessesImg',
                  )}
                </>
              )}
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Agency Name</div>
              <EllipsisText
                text={
                  data?.getEqcStageHistoryWithoutAuth?.projectAgency?.agency
                    ?.name || '-'
                }
              />
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">Location</div>
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.latitude &&
                data?.getEqcStageHistoryWithoutAuth?.longitude ? (
                  <a
                    href={`https://maps.google.com/?q=${data?.getEqcStageHistoryWithoutAuth?.latitude},${data?.getEqcStageHistoryWithoutAuth?.longitude}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      Event(GA_EVENT.VIEW_LOCATION, {
                        label: GA_LABEL.VIEW_LOCATION,
                        // eslint-disable-next-line no-undef
                        pathname: window?.location?.href,
                      });
                    }}
                  >
                    View Location
                  </a>
                ) : (
                  '-'
                )}
              </div>
            </Space>
          </Col>
          {data?.getEqcStageHistoryWithoutAuth?.isRfiStage ? (
            <Col span={isDesktopViewport ? 4 : 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary fw-medium">
                  Inspection Requested On
                </div>
                <div>
                  {data?.getEqcStageHistoryWithoutAuth?.inspectorReminderDate &&
                  data?.getEqcStageHistoryWithoutAuth?.status
                    ? moment(
                        data?.getEqcStageHistoryWithoutAuth
                          ?.inspectorReminderDate,
                      )
                        ?.tz(
                          data?.getEqcStageHistoryWithoutAuth?.project
                            ?.timeZone,
                        )
                        ?.format(DATETIMEWITHDIVIDE)
                    : '-'}
                </div>
              </Space>
            </Col>
          ) : (
            <Col span={isDesktopViewport ? 4 : 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary fw-medium">TimeZone</div>
                <div>
                  {data?.getEqcStageHistoryWithoutAuth?.project?.timeZone}
                </div>
              </Space>
            </Col>
          )}
        </Row>
        {data?.getEqcStageHistoryWithoutAuth?.isRfiStage && (
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">TimeZone</div>
              <div>
                {data?.getEqcStageHistoryWithoutAuth?.project?.timeZone}
              </div>
            </Space>
          </Col>
        )}
        <CommentSection data={data} />
      </Card>
    </>
  );
};

export default StageSummary;
