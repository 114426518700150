import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row, Switch } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../common/constants';
import { Event } from '../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../common/utils';
import { CREATE_EQC_STAGE, UPDATE_EQC_STAGE } from '../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const AddEditStageModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    stageData,
    setStageData,
    eqcTypeId,
    refetchStageDataWithInitialValues,
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const [createStage, { loading: createLoading }] = useMutation(
    CREATE_EQC_STAGE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_CHECKLIST_STAGE, {
          label: GA_LABEL.ADD_GLOBAL_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          checklist_id: eqcTypeId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      },
    },
  );

  const [updateStage, { loading: updateLoading }] = useMutation(
    UPDATE_EQC_STAGE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_CHECKLIST_STAGE, {
          label: GA_LABEL.EDIT_GLOBAL_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          checklist_id: eqcTypeId,
          stage_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      },
    },
  );

  const onAgencySubmitFinish = async (formValues) => {
    const newFormValues = {
      ...formValues,
      eqcTypeId: Number(eqcTypeId),
      witness: formValues.witness || false,
      drawing: formValues.drawing || false,
    };
    const variables = isUpdate
      ? { data: newFormValues, id: stageData.id }
      : newFormValues;

    try {
      if (isUpdate) {
        await updateStage({
          variables: { ...variables },
        });
        return;
      }
      await createStage({
        variables: { data: { ...variables } },
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="dialog"
      footer={null}
      closable={false}
    >
      <h2>{isUpdate ? 'Edit Stage' : 'Add Stage'}</h2>
      <Form
        form={form}
        initialValues={isUpdate && stageData}
        layout="vertical"
        onFinish={onAgencySubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Stage name cannot be more than 250 characters',
            },
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Stage Name" />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item
              name="witness"
              label="Witness Required"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="drawing"
              label="Drawing Required"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditStageModal;
