import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, Skeleton, Tag, message } from 'antd';
import clsx from 'clsx';
import { includes, omit, unionBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  AddButton,
  CopyOutline,
  DisableIcon,
  Export,
  FileTextOutline,
  GoLiveIcon,
  IndentIcon,
  InfoIcon,
  Lock,
  OutdentIcon,
  RightIcon,
  TrashOutline,
} from '../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ADDONS,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  CSV_SAMPLE_FILES,
  GA_EVENT,
  GA_LABEL,
  NOMENCLATURE_ACTION_BUTTON_URL,
  NOMENCLATURE_CHART_NODE_COLORS,
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import { downloadImageByHtmlNode } from '../../../../../../common/utils';
import CanPerform from '../../../../../../components/CanPerform';
import CommonImportModal from '../../../../../../components/CommonImportModal';
import CommonPopover from '../../../../../../components/CommonPopover';
import CommonTooltip from '../../../../../../components/CommonTooltip';
import HasAccess from '../../../../../../components/HasAccess';
import Portal from '../../../../../../components/Portal';
import { IMPORT_PROJECT_NOMENCLATURE_CSV } from '../../../../graphql/Mutation';
import { PROJECT_NOMENCLATURE_LIST } from '../../../../graphql/Queries';
import AddEditLevelsModal from './AddEditLevelsModal';
import CreateModal from './CreateModal';
import DeleteLevelModal from './DeleteLevelModal';
import DisabledState from './DisabledState';
import DuplicateLevelModal from './DuplicateLevelModal';
import EnableDisableModal from './EnableDisableModal';
import GoLiveModal from './GoLiveModal';
import ImportConfirmationModal from './ImportConfirmationModal';
import IndentOutdentModal from './IndentOutdentModal';
import NomenclatureTree from './NomenclatureTree';
import RequestAccessModal from './RequestAccessModal';

const position = { x: 0, y: 0 };
let initialNodes = [];
const pushNode = (item) => {
  initialNodes.push({
    id: `${item?.id}`,
    data: {
      label: (
        <CommonPopover
          overlayClassName="pass-fail-count"
          content={
            <div>
              <div>
                Pass :{' '}
                <span className="count ">{item?.passInspectionCount}</span>
              </div>
              <div>
                Fail :{' '}
                <span className="count ">{item?.failInspectionCount}</span>
              </div>
            </div>
          }
        >
          {item?.name}
        </CommonPopover>
      ),
      data: item,
    },
    style: {
      background:
        NOMENCLATURE_CHART_NODE_COLORS[
          item?.isActive ? item?.level : 'disabled'
        ]?.background,
      border: `1px solid ${
        NOMENCLATURE_CHART_NODE_COLORS[
          item?.isActive ? item?.level : 'disabled'
        ]?.border
      }`,
      width: 'auto',
      minWidth: '150px',
      ...(!item?.isActive && {
        textDecoration: 'line-through',
        textDecorationColor: '#000',
      }),
    },
    position,
  });
};
const getChartNode = async (list) => {
  list?.forEach(async (item) => {
    if (item?.data?.length > 0) {
      pushNode(item);
      await getChartNode(item?.data);
    }
    // if (
    //   includes(
    //     expandedKeys,
    //     `${item?.level === 0 ? item?.id : item?.parentLevelId}`
    //   )
    // ) {
    //! commented for future use
    pushNode(item);
    // }    //! commented for future use
  });
};
const NomenclatureWrapper = ({ projectData, projectDataRefetch }) => {
  const { getToken, getTenantUser, getCurrentUser } = useContext(AppContext);
  const isProjectActive = projectData?.isActive;
  const token = getToken();
  const currentUser = getCurrentUser();
  const [showImportModal, setShowImportModal] = useState(false);
  const [nomenclatureList, setNomenclatureList] = useState([]);
  const [nomenclatureChartList, setNomenclatureChartList] = useState([]);
  const [showAddEditLevelModal, setShowAddEditLevelModal] = useState(false);
  const [showDeleteLevelModal, setShowDeleteLevelModal] = useState(false);
  const [showEnableDisableModal, setShowEnableDisableModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showIndentOutdentModal, setShowIndentOutdentModal] = useState(false);
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedTree, setSelectedTree] = useState();
  const [chartNodes, setChartNodes] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState();
  const [loading, setLoading] = useState(true);
  //! commented for future use
  // eslint-disable-next-line no-unused-vars
  const [showListView, setShowListView] = useState(true);
  const [isDisabledNodeSelected, setIsDisabledNodeSelected] = useState(false);
  const [checkedTreeNode, setCheckedTreeNode] = useState();
  const [isIndent, setIsIndent] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [showImportConfirmationModal, setShowImportConfirmationModal] =
    useState(false);
  const {
    params: { projectId },
  } = useRouter();
  const nomenclatureListLength = nomenclatureList?.[0]?.data?.length > 0;

  const tenantUser = getTenantUser();
  const chartRef = useRef(null);

  const handleCsvDownload = () => {
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/nomenclature/${projectId}/export?access_token=Bearer ${token}`;
    // eslint-disable-next-line no-undef
    window.open(url, '_blank');
    Event(GA_EVENT.EXPORT_PROJECT_NOMENCLATURE_CSV, {
      label: GA_LABEL.EXPORT_PROJECT_NOMENCLATURE_CSV,
      // eslint-disable-next-line no-undef
      pathname: window?.location?.href,
      projectId,
      user_id: currentUser?.id,
      user_name: currentUser?.name,
      tenant_id: currentUser?.tenantUser?.tenant?.id,
      tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
    });
  };
  const isNomenclatureActive = includes(
    tenantUser?.tenant?.addOn,
    ADDONS?.NOMENCLATURE,
  );

  const sampleFileUrl = CSV_SAMPLE_FILES.NOMENCLATURE_CSV;
  const listPoints = ['Level Name', 'Outline level'];

  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [projectNomenclatureList] = useLazyQuery(PROJECT_NOMENCLATURE_LIST, {
    variables: {
      filter: {
        projectId,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const rootNode = [omit(res?.projectNomenclatureList, 'data')];
      rootNode[0].data = res?.projectNomenclatureList?.data;
      setNomenclatureList(rootNode);
      setNomenclatureChartList(rootNode);
      setLoading(false);
    },
    onError() {},
  });

  const [importProjectNomenclatureCsv, { loading: importLoading }] =
    useMutation(IMPORT_PROJECT_NOMENCLATURE_CSV, {
      onError() {},
    });

  useMemo(() => {
    if (nomenclatureList?.length > 0) {
      initialNodes = [];
      getChartNode(nomenclatureList);
      setChartNodes(unionBy(initialNodes, 'id'));
    }
  }, [nomenclatureList]);

  const handleRefetch = () => {
    if (isNomenclatureActive) {
      projectNomenclatureList();
    }
  };

  useEffect(() => {
    if (isNomenclatureActive) {
      projectNomenclatureList();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadImage = async () => {
    //! case 1
    await downloadImageByHtmlNode(
      '.react-flow',
      `${nomenclatureList?.[0]?.name}_nomenclature`,
      chartRef,
    );

    //! case 2
    // // Fit the chart to the view
    // reactFlowInstance.current.fitView();
    // // Wait for the chart to render after fitting the view
    // setTimeout(() => {
    //   // Capture the chart container as an image using html-to-image
    //   htmlToImage
    //     .toBlob(chartRef.current)
    //     .then(function (blob) {
    //       // Trigger the download
    //       // eslint-disable-next-line no-undef
    //       const link = document.createElement('a');
    //       link.download = 'chart.png';
    //       link.href = URL.createObjectURL(blob);
    //       link.click();
    //     })
    //     .catch(function (error) {
    //       // eslint-disable-next-line no-console
    //       console.error('Error downloading chart:', error);
    //     });
    // }, 100); // Adjust the delay as needed
  };

  if (loading) {
    return <Skeleton className="p-14" active paragraph={{ rows: 12 }} />;
  }

  const renderTreeView = () => {
    return (
      <NomenclatureTree
        nomenclatureList={nomenclatureList}
        setNomenclatureList={setNomenclatureList}
        setNomenclatureChartList={setNomenclatureChartList}
        nomenclatureChartList={nomenclatureChartList}
        setShowAddEditLevelModal={setShowAddEditLevelModal}
        setSelectedTree={setSelectedTree}
        chartNodes={chartNodes}
        setCheckedTreeNode={setCheckedTreeNode}
        checkedTreeNode={checkedTreeNode}
        setCheckedKeys={setCheckedKeys}
        checkedKeys={checkedKeys}
        setIsDisabledNodeSelected={setIsDisabledNodeSelected}
        isDisabledNodeSelected={isDisabledNodeSelected}
        loading={loading}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        handleRefetch={handleRefetch}
        isProjectActive={isProjectActive}
      />
    );
  };
  //! commented for future use
  // const renderChartView = () => {
  //   return (
  //     <NomenclatureChart
  //       nomenclatureList={nomenclatureChartList}
  //       setChartNodes={setChartNodes}
  //       expandedKeys={expandedKeys}
  //       setExpandedKeys={setExpandedKeys}
  //       chartRef={chartRef}
  //       chartNodes={chartNodes}
  //     />
  //   );
  // };

  return (
    <>
      {isProjectActive && isNomenclatureActive && nomenclatureListLength && (
        <Portal portalId="tabs-footer">
          <div className="d-flex nomenclature-extra-info">
            Unlinked count
            {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
              <div>
                &nbsp;: EQC -&nbsp;
                {Number(nomenclatureChartList?.[0]?.unassignedEqcCount)}
              </div>
            )}
            {HasAccess({ type: ACCESS_TYPE.INSTRUCTION }) && (
              <div>
                &nbsp;: Instruction -&nbsp;
                {Number(nomenclatureChartList?.[0]?.unassignedInstructionCount)}
              </div>
            )}
          </div>
        </Portal>
      )}
      {!(isNomenclatureActive && nomenclatureListLength) && (
        <Portal portalId="create-button">
          {isNomenclatureActive
            ? isProjectActive && (
                <CanPerform
                  action={ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE}
                  type={ALLOWED_ACTION_TYPE.BOTH}
                >
                  <Button
                    shape="round"
                    icon={<AddButton className="mr-5" height={20} width={20} />}
                    type="primary"
                    className="mr-10"
                    onClick={() => {
                      if (isDesktopViewport) {
                        setShowCreateModal(true);
                      } else {
                        message.destroy();
                        message.warning({
                          content:
                            'Editing allowed only from desktop web browser',
                          icon: (
                            <InfoIcon height={18} width={18} className="mr-5" />
                          ),
                          className: 'nomenclature-mobile-info',
                        });
                      }
                    }}
                  >
                    Create
                  </Button>
                </CanPerform>
              )
            : isProjectActive && (
                <Button
                  shape="round"
                  icon={<Lock className="mr-5" height={20} width={20} />}
                  type="primary"
                  className="mr-10"
                  onClick={() => setShowRequestAccessModal(true)}
                >
                  Request Access
                </Button>
              )}
        </Portal>
      )}

      <div className="project-tab-details nomenclature-module">
        <Row
          gutter={10}
          justify="space-between"
          align="middle"
          className="top-row"
        >
          <Col span={isDesktopViewport ? 6 : 24}>
            <div id="search-div" />
          </Col>
          <Col
            span={isDesktopViewport ? 18 : 24}
            className="d-flex align-center justify-end"
          >
            {/* commented for future use */}
            {/* {isNomenclatureActive && nomenclatureListLength && (
              <Segmented
                options={['List', 'Chart']}
                value={showListView ? 'List' : 'Chart'}
                onChange={(e) => {
                  setShowListView(e === 'List');
                }}
              />
            )} */}
            <div className="d-flex justify-end main-action-buttons align-center">
              {
                // eslint-disable-next-line no-nested-ternary
                isNomenclatureActive ? (
                  nomenclatureListLength ? (
                    <>
                      {nomenclatureList?.[0]?.isNomenclaturePublished &&
                        CanPerform({
                          action: ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE,
                          type: ALLOWED_ACTION_TYPE.BOTH,
                        }) && (
                          <Button
                            shape="round"
                            type="primary"
                            className="d-flex b-0 mr-10"
                            onClick={() => setShowGoLiveModal(true)}
                          >
                            {isDesktopViewport && 'Deactivate'}
                          </Button>
                        )}
                      <Button
                        shape="round"
                        icon={
                          <Export
                            className={clsx(isDesktopViewport && 'mr-5')}
                            height={20}
                            width={20}
                          />
                        }
                        className="export-button d-flex b-0 mr-10"
                        onClick={() => {
                          const handleClick = showListView
                            ? handleCsvDownload
                            : downloadImage;
                          handleClick();
                        }}
                      >
                        {isDesktopViewport && 'Export'}
                      </Button>
                      {!nomenclatureList?.[0]?.isNomenclaturePublished ? (
                        isDesktopViewport &&
                        isProjectActive && (
                          <CanPerform
                            action={ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE}
                            type={ALLOWED_ACTION_TYPE.BOTH}
                          >
                            {!nomenclatureList?.[0]
                              ?.isImportNomenclatureDisabled && (
                              <Button
                                shape="round"
                                icon={
                                  <FileTextOutline
                                    className={clsx(
                                      isDesktopViewport && 'mr-5',
                                    )}
                                    height={20}
                                    width={20}
                                  />
                                }
                                type="primary"
                                className="mr-10"
                                onClick={() => {
                                  if (nomenclatureListLength) {
                                    setShowImportConfirmationModal(true);
                                  } else {
                                    setShowImportModal(true);
                                  }
                                }}
                              >
                                {isDesktopViewport && 'Import CSV'}
                              </Button>
                            )}
                            <Button
                              shape="round"
                              icon={
                                <GoLiveIcon
                                  className={clsx(isDesktopViewport && 'mr-5')}
                                  height={20}
                                  width={20}
                                />
                              }
                              type="primary"
                              className="mr-10"
                              onClick={() => setShowGoLiveModal(true)}
                            >
                              {isDesktopViewport && 'Go live'}
                            </Button>
                          </CanPerform>
                        )
                      ) : (
                        <Tag className="live-tag">LIVE</Tag>
                      )}
                    </>
                  ) : (
                    isProjectActive && (
                      <CanPerform
                        action={ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE}
                        type={ALLOWED_ACTION_TYPE.BOTH}
                      >
                        {isDesktopViewport && (
                          <Button
                            shape="round"
                            icon={
                              <FileTextOutline
                                className={clsx(isDesktopViewport && 'mr-5')}
                                height={20}
                                width={20}
                              />
                            }
                            type="primary"
                            className="mr-10"
                            onClick={() => setShowImportModal(true)}
                          >
                            {isDesktopViewport && 'Import CSV'}
                          </Button>
                        )}
                        <Button
                          shape="round"
                          icon={
                            <AddButton
                              className={clsx(isDesktopViewport && 'mr-5')}
                              height={20}
                              width={20}
                            />
                          }
                          type="primary"
                          className="mr-10"
                          onClick={() => {
                            if (isDesktopViewport) {
                              setShowCreateModal(true);
                            } else {
                              message.destroy();
                              message.warning({
                                content:
                                  'Editing allowed only from desktop web browser',
                                icon: (
                                  <InfoIcon
                                    height={18}
                                    width={18}
                                    className="mr-5"
                                  />
                                ),
                                className: 'nomenclature-mobile-info',
                              });
                            }
                          }}
                        >
                          {isDesktopViewport && 'Create'}
                        </Button>
                      </CanPerform>
                    )
                  )
                ) : (
                  isProjectActive && (
                    <Button
                      shape="round"
                      icon={
                        <Lock
                          className={clsx(isDesktopViewport && 'mr-5')}
                          height={20}
                          width={20}
                        />
                      }
                      type="primary"
                      className="mr-10"
                      onClick={() => setShowRequestAccessModal(true)}
                    >
                      {isDesktopViewport && 'Request Access'}
                    </Button>
                  )
                )
              }
            </div>
            {!CanPerform({
              action: ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE,
              type: ALLOWED_ACTION_TYPE.BOTH,
            }) && (
              <CommonPopover
                placement="left"
                content="Only Project and System admin have editing rights"
              >
                <Tag className="view-only-tag">View Only</Tag>
              </CommonPopover>
            )}
          </Col>
        </Row>
        {isDesktopViewport ? (
          <>
            {isNomenclatureActive && nomenclatureListLength ? (
              <>
                {showListView ? (
                  <div className="tree-col">
                    {renderTreeView()}
                    {checkedTreeNode?.length > 0 && (
                      <div className="sticky-action-buttons">
                        {!isDisabledNodeSelected && (
                          <>
                            <CommonTooltip
                              placement="left"
                              title={
                                <>
                                  This will be not be part of the level above.{' '}
                                  <a
                                    href={
                                      NOMENCLATURE_ACTION_BUTTON_URL.OUTDENT
                                    }
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Click here
                                  </a>{' '}
                                  to learn more in detail.
                                </>
                              }
                            >
                              <div
                                onClick={() => {
                                  if (
                                    checkedTreeNode?.[0]?.data?.data?.level !==
                                    1
                                  ) {
                                    setIsIndent(false);
                                    setShowIndentOutdentModal(true);
                                  }
                                }}
                                className={
                                  checkedTreeNode?.[0]?.data?.data?.level === 1
                                    ? 'cursor-disabled'
                                    : ''
                                }
                              >
                                <OutdentIcon />
                                <div className="button-title">Outdent</div>
                              </div>
                            </CommonTooltip>
                            <CommonTooltip
                              placement="left"
                              title={
                                <>
                                  This will be part of the level above.{' '}
                                  <a
                                    href={NOMENCLATURE_ACTION_BUTTON_URL.INDENT}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Click here
                                  </a>{' '}
                                  to learn more in detail.
                                </>
                              }
                            >
                              <div
                                onClick={() => {
                                  setIsIndent(true);
                                  setShowIndentOutdentModal(true);
                                }}
                              >
                                <IndentIcon />
                                <div className="button-title">Indent</div>
                              </div>
                            </CommonTooltip>
                          </>
                        )}
                        <CommonTooltip
                          placement="left"
                          title={
                            <>
                              This will allow you to duplicate all the level in
                              another level with all sub-levels.{' '}
                              <a
                                href={NOMENCLATURE_ACTION_BUTTON_URL.DUPLICATE}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Click here
                              </a>{' '}
                              to learn more in detail.
                            </>
                          }
                        >
                          <div onClick={() => setShowDuplicateModal(true)}>
                            <CopyOutline />
                            <div className="button-title">Duplicate</div>
                          </div>
                        </CommonTooltip>
                        {!isDisabledNodeSelected && (
                          <>
                            <CommonTooltip
                              placement="left"
                              title={
                                <>
                                  This will be permanently delete all the level
                                  incase EQC is linked, level will be disable.{' '}
                                  <a
                                    href={NOMENCLATURE_ACTION_BUTTON_URL.DELETE}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Click here
                                  </a>{' '}
                                  to learn more in detail.
                                </>
                              }
                            >
                              <div
                                onClick={() => setShowDeleteLevelModal(true)}
                              >
                                <TrashOutline />
                                <div className="button-title">Delete</div>
                              </div>
                            </CommonTooltip>
                            <CommonTooltip
                              placement="left"
                              title={
                                <>
                                  This will disable all the level and can be
                                  enable later.{' '}
                                  <a
                                    href={
                                      NOMENCLATURE_ACTION_BUTTON_URL.DISABLE
                                    }
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Click here
                                  </a>{' '}
                                  to learn more in detail.
                                </>
                              }
                            >
                              <div
                                onClick={() => setShowEnableDisableModal(true)}
                              >
                                <DisableIcon />
                                <div className="button-title">Disable</div>
                              </div>
                            </CommonTooltip>
                          </>
                        )}
                        {isDisabledNodeSelected && (
                          <CommonTooltip
                            placement="left"
                            title={
                              <>
                                This will enable all the level.{' '}
                                <a
                                  href={NOMENCLATURE_ACTION_BUTTON_URL.ENABLE}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Click here
                                </a>{' '}
                                to learn more in detail.
                              </>
                            }
                          >
                            <div
                              onClick={() => setShowEnableDisableModal(true)}
                            >
                              <RightIcon
                                height={22}
                                width={22}
                                className="enable-icon"
                              />
                              <div className="button-title">Enable</div>
                            </div>
                          </CommonTooltip>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="chart-structure">
                    {/* commented for future use */}
                    {/* <div className="react-flow-chart">{renderChartView()}</div> */}
                  </div>
                )}
              </>
            ) : (
              !loading && <DisabledState />
            )}
          </>
        ) : (
          <div className="nomenclature-mobile">
            <div className="detail">
              {
                // eslint-disable-next-line no-nested-ternary
                isNomenclatureActive && nomenclatureListLength ? (
                  showListView ? (
                    <div className="tree-structure">{renderTreeView()}</div>
                  ) : (
                    <>{/* {renderChartView()} */}</>
                  )
                ) : (
                  <DisabledState />
                )
              }
            </div>
          </div>
        )}
      </div>
      {showImportModal && (
        <CommonImportModal
          showImportModal={showImportModal}
          setShowImportModal={setShowImportModal}
          title="CSV"
          sampleFileUrl={sampleFileUrl}
          list={listPoints}
          projectId={projectId}
          importProjectNomenclatureCsv={importProjectNomenclatureCsv}
          importLoading={importLoading}
          refetchData={handleRefetch}
          isAlreadyHavingData={nomenclatureListLength}
          filePrefix="nomenclatureCsv"
          additionalPoints={[
            'Maximum 9000 level items can be added and with maximum of 7 outline levels.',
            'Max characters limit is 40',
            <>
              <a
                href={NOMENCLATURE_ACTION_BUTTON_URL.IMPORT}
                rel="noreferrer"
                target="_blank"
              >
                Click here
              </a>{' '}
              to understand more in detail.
            </>,
          ]}
        />
      )}
      {showAddEditLevelModal && (
        <AddEditLevelsModal
          showModal={showAddEditLevelModal}
          setShowModal={setShowAddEditLevelModal}
          projectId={projectId}
          handleRefetch={handleRefetch}
          selectedTree={selectedTree}
          setSelectedTree={setSelectedTree}
          setExpandedKeys={setExpandedKeys}
        />
      )}
      {showDeleteLevelModal && (
        <DeleteLevelModal
          showModal={showDeleteLevelModal}
          setShowModal={setShowDeleteLevelModal}
          projectId={projectId}
          handleRefetch={handleRefetch}
          setCheckedTreeNode={setCheckedTreeNode}
          checkedTreeNode={checkedTreeNode}
          setCheckedKeys={setCheckedKeys}
          checkedKeys={checkedKeys}
        />
      )}
      {showEnableDisableModal && (
        <EnableDisableModal
          showModal={showEnableDisableModal}
          setShowModal={setShowEnableDisableModal}
          handleRefetch={handleRefetch}
          setCheckedTreeNode={setCheckedTreeNode}
          setCheckedKeys={setCheckedKeys}
          checkedKeys={checkedKeys}
          isDisabledNodeSelected={isDisabledNodeSelected}
        />
      )}
      {showDuplicateModal && (
        <DuplicateLevelModal
          showModal={showDuplicateModal}
          setShowModal={setShowDuplicateModal}
          handleRefetch={handleRefetch}
          nomenclatureList={nomenclatureList}
          setNomenclatureList={setNomenclatureList}
          setSelectedTree={setSelectedTree}
          chartNodes={chartNodes}
          setCheckedTreeNode={setCheckedTreeNode}
          checkedTreeNode={checkedTreeNode}
          setCheckedKeys={setCheckedKeys}
          checkedKeys={checkedKeys}
          projectId={projectId}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          isProjectActive={isProjectActive}
        />
      )}
      {showIndentOutdentModal && (
        <IndentOutdentModal
          showModal={showIndentOutdentModal}
          setShowModal={setShowIndentOutdentModal}
          handleRefetch={handleRefetch}
          setSelectedTree={setSelectedTree}
          setCheckedTreeNode={setCheckedTreeNode}
          checkedTreeNode={checkedTreeNode}
          setCheckedKeys={setCheckedKeys}
          projectId={projectId}
          isIndent={isIndent}
          setExpandedKeys={setExpandedKeys}
        />
      )}
      {showGoLiveModal && (
        <GoLiveModal
          showModal={showGoLiveModal}
          setShowModal={setShowGoLiveModal}
          handleRefetch={handleRefetch}
          setSelectedTree={setSelectedTree}
          setCheckedTreeNode={setCheckedTreeNode}
          checkedTreeNode={checkedTreeNode}
          setCheckedKeys={setCheckedKeys}
          projectId={projectId}
          isIndent={isIndent}
          data={nomenclatureList}
        />
      )}
      {showRequestAccessModal && (
        <RequestAccessModal
          showModal={showRequestAccessModal}
          setShowModal={setShowRequestAccessModal}
          projectId={projectId}
          isAlreadyRequested={projectData?.isNomenclatureRequestSent}
          projectDataRefetch={projectDataRefetch}
        />
      )}
      {showCreateModal && (
        <CreateModal
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
          handleClickEvent={() => {
            setSelectedTree(nomenclatureList?.[0]);
            setShowAddEditLevelModal(true);
          }}
        />
      )}
      {showImportConfirmationModal && (
        <ImportConfirmationModal
          showModal={showImportConfirmationModal}
          setShowModal={setShowImportConfirmationModal}
          handleClickEvent={() => {
            setShowImportModal(true);
          }}
        />
      )}
    </>
  );
};
function Nomenclature(props) {
  return (
    //! commented for future use
    // <ReactFlowProvider>
    <NomenclatureWrapper {...props} />
    // </ReactFlowProvider>
  );
}

export default Nomenclature;
