import { useLazyQuery } from '@apollo/client';
import { Button, Col, Descriptions, Image, Row } from 'antd';
import clsx from 'clsx';
import { includes, keys, map, toUpper } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { InfoIconDark } from '../../../assets/svg';
import {
  ACCESS_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  IMAGE_FILTERS,
  INS_STATUS_KEYS,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import useRouter from '../../../common/useRouter';
import { removeHistoryStateData, titleCase } from '../../../common/utils';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonPopover from '../../../components/CommonPopover';
import CommonSelect from '../../../components/CommonSelect';
import EllipsisText from '../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import CustomRangePicker from '../../dashboard/components/CustomRangePicker';
import {
  ASSET_EQC_TYPE_DROPDOWN_LIST,
  ASSET_EQC_TYPE_STAGE_DROPDOWN_LIST,
  ASSET_EQC_TYPE_STAGE_ITEM_DROPDOWN_LIST,
  ASSET_PROJECT_DROPDOWN_LIST,
} from '../../projects/graphql/Queries';
import { TENANT_ASSET_LIST } from '../graphql/Queries';

const Gallery = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [selectedDates, setSelectedDates] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const { navigate, location } = useRouter();
  const [featureType, setFeatureType] = useState();
  const { getTenantUser } = useContext(AppContext);
  const tenantData = getTenantUser()?.tenant;
  const instructionFilter = location?.state?.instructionFilter;
  const instructionPagination = location?.state?.instructionPagination;
  const initialInstructionLogFilter = {
    skip: 0,
    limit: 30,
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const [paginationProp, setPaginationProp] = useState(
    instructionPagination || initialPaginationValue,
  );

  const [instructionLogFilter, setInstructionLogFilter] = useState(
    instructionFilter || initialInstructionLogFilter,
  );
  const [projectId, setProjectId] = useState(instructionFilter?.projectId);
  const [selectedProjectRecord, setSelectedProjectRecord] = useState();
  const [status, setStatus] = useState(instructionFilter?.status);
  const [imageFilter, setImageFilter] = useState(
    instructionFilter?.imageFilter || tenantData?.access?.length > 1
      ? IMAGE_FILTERS?.ALL_IMAGES
      : IMAGE_FILTERS[`${tenantData?.access?.[0]}S`],
  );
  const [eqcType, setEqcType] = useState(instructionFilter?.eqcType);
  const [eqcStage, setEqcStage] = useState(instructionFilter?.eqcStage);
  const [eqcStageItem, setEqcStageItem] = useState(
    instructionFilter?.eqcStageItem,
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setProjectId(instructionFilter?.projectId);
  }, [instructionFilter?.projectId]);

  useEffect(() => {
    setStatus(instructionFilter?.status);
  }, [instructionFilter?.status]);

  const [fetchInstructionLogs, { loading }] = useLazyQuery(TENANT_ASSET_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.tenantAssetList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.tenantAssetList?.total,
      };
      setPaginationProp(pagination);
      setHasMore(!!data?.length);
      if (scrollFlag) {
        const datalist = [...activityData, ...data];
        setActivityData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setActivityData(datalist);
      }
    },
    onError() {},
  });

  useEffect(() => {
    const newFilter = {
      ...instructionLogFilter,
      projectId,
      status,
      projectEqcTypeId: eqcType,
      projectEqcTypeStageId: eqcStage,
      projectEqcTypeStageItemId: eqcStageItem,
      featureType,
      skip: 0,
      fromDate: selectedDates?.[0] && moment(selectedDates?.[0]).startOf('day'),
      endDate: selectedDates?.[1] && moment(selectedDates?.[1]).endOf('day'),
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setInstructionLogFilter(newFilter);
    fetchInstructionLogs({ variables: { filter: { ...newFilter } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    status,
    eqcType,
    eqcStage,
    eqcStageItem,
    projectId,
    featureType,
    selectedDates,
  ]);

  const instructionLogsData = async (value) => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: 0,
          limit: DEFAULT_PAGE_SIZE,
          search: value,
        },
      },
    });
    setInstructionLogFilter({
      ...instructionLogFilter,
      skip: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: value,
    });
  };

  const handleRefetch = () => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: activityData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  const items = [
    {
      key: 'filter',
      children: (
        <Row
          justify={isDesktopViewport ? 'end' : 'start'}
          className="d-flex filter-search align-center"
          wrap
          gutter={isDesktopViewport ? [10, 10] : [0, 10]}
        >
          {imageFilter !== IMAGE_FILTERS.ALL_IMAGES && (
            <Col span={isDesktopViewport ? null : 12}>
              <CommonDropdown
                allowClear
                placeholder="Project"
                className={`instruction-selector dropdown-width-auto ${
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                } `}
                value={projectId}
                onChange={(id, record) => {
                  setProjectId(id);
                  setSelectedProjectRecord(record);
                  setEqcType();
                  setEqcStage();
                  setEqcStageItem();
                  if (!id) {
                    removeHistoryStateData(
                      navigate,
                      location,
                      'instructionFilter',
                      'projectId',
                    );
                  }
                }}
                query={ASSET_PROJECT_DROPDOWN_LIST}
                fetchPolicy="network-only"
                responsePath="assetProjectDropdownList.data"
                valuePath="id"
                labelPath="name"
                optionKey="project"
                showSearch
                optionFilterProp="children"
                customOptions={selectedProjectRecord}
                dropdownMatchSelectWidth={false}
              />
            </Col>
          )}
          {imageFilter === IMAGE_FILTERS?.INSPECTIONS && (
            <>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Checklist"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcType}
                  onChange={(type) => {
                    setEqcType(type);
                    setEqcStage();
                    setEqcStageItem();
                    if (!type) {
                      removeHistoryStateData(
                        navigate,
                        location,
                        'eqcFilter',
                        'eqcType',
                      );
                    }
                  }}
                  variables={{
                    filter: { projectId },
                  }}
                  query={ASSET_EQC_TYPE_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="assetProjectEqcTypeDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="eqc-type"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  useEffectDeps={[projectId]}
                  conditionToCheckBeforeQuery={!!projectId}
                  projectId={projectId}
                  disabled={!projectId}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Stage"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcStage}
                  onChange={(stage) => {
                    setEqcStage(stage);
                    setEqcStageItem();
                    if (!stage) {
                      removeHistoryStateData(
                        navigate,
                        location,
                        'eqcFilter',
                        'eqcType',
                      );
                    }
                  }}
                  query={ASSET_EQC_TYPE_STAGE_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="assetProjectEqcTypeStageDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="eqc-type"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  variables={{
                    filter: { projectEqcTypeId: eqcType },
                  }}
                  useEffectDeps={[eqcType]}
                  conditionToCheckBeforeQuery={!!eqcType}
                  projectId={eqcType}
                  disabled={!eqcType}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Stage Item"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcStageItem}
                  onChange={(type) => {
                    setEqcStageItem(type);
                    if (!type) {
                      removeHistoryStateData(
                        navigate,
                        location,
                        'eqcFilter',
                        'eqcType',
                      );
                    }
                  }}
                  query={ASSET_EQC_TYPE_STAGE_ITEM_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="assetProjectEqcTypeStageItemDropdownList.data"
                  valuePath="id"
                  labelPath="title"
                  optionKey="eqc-type"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  variables={{
                    filter: { projectEqcTypeStageId: Number(eqcStage) },
                  }}
                  useEffectDeps={[eqcStage]}
                  conditionToCheckBeforeQuery={!!eqcStage}
                  projectId={eqcStage}
                  disabled={!eqcStage}
                />
              </Col>
            </>
          )}
          {imageFilter === IMAGE_FILTERS?.INSTRUCTIONS && (
            <Col span={isDesktopViewport ? null : 12}>
              <CommonSelect
                allowClear
                name="status"
                placeholder="Issue Type"
                className={`instruction-selector ${
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                } `}
                value={status}
                onChange={(eqcStatus) => {
                  setStatus(eqcStatus);
                  if (!eqcStatus) {
                    removeHistoryStateData(
                      navigate,
                      location,
                      'instructionFilter',
                      'status',
                    );
                  }
                }}
                options={[
                  ...keys(INS_STATUS_KEYS).map((statusText) => {
                    return {
                      key: statusText,
                      value: statusText,
                      label: titleCase(statusText),
                    };
                  }),
                ]}
              />
            </Col>
          )}
        </Row>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-between width-percent-100">
        {isDesktopViewport && (
          <div className="image-count">{`${paginationProp?.total} Images`}</div>
        )}
        <div>
          <Row
            justify={isDesktopViewport ? 'end' : 'start'}
            className="d-flex filter-search align-center mb-10"
            wrap
            gutter={isDesktopViewport ? [10, 10] : [0, 10]}
          >
            <Col span={isDesktopViewport ? null : 24}>
              <CustomRangePicker
                className={clsx(!isDesktopViewport && 'width-percent-100')}
                setDateSelected={setSelectedDates}
                selectedDates={selectedDates}
              />
            </Col>
            <Col span={isDesktopViewport ? null : 24}>
              <CommonSelect
                name="imageFilter"
                className={`instruction-selector ${
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                } `}
                value={imageFilter}
                onChange={(text) => {
                  if (text !== IMAGE_FILTERS?.ALL_IMAGES) {
                    setFeatureType(toUpper(text));
                  } else {
                    setFeatureType();
                  }
                  setStatus();
                  setProjectId();
                  setEqcType();
                  setEqcStage();
                  setEqcStageItem();
                  setImageFilter(text);
                  if (!text) {
                    removeHistoryStateData(
                      navigate,
                      location,
                      'instructionFilter',
                      'imageFilter',
                    );
                  }
                }}
                options={[
                  ...(tenantData?.access?.length > 1
                    ? [
                        {
                          key: IMAGE_FILTERS?.ALL_IMAGES,
                          value: IMAGE_FILTERS?.ALL_IMAGES,
                          label: `${titleCase(IMAGE_FILTERS?.ALL_IMAGES)}s`,
                        },
                      ]
                    : []),

                  ...(includes(tenantData?.access, ACCESS_TYPE?.INSPECTION)
                    ? [
                        {
                          key: IMAGE_FILTERS?.INSPECTIONS,
                          value: IMAGE_FILTERS?.INSPECTIONS,
                          label: `${titleCase(IMAGE_FILTERS?.INSPECTIONS)}s`,
                        },
                      ]
                    : []),

                  ...(includes(tenantData?.access, ACCESS_TYPE?.INSTRUCTION)
                    ? [
                        {
                          key: IMAGE_FILTERS?.INSTRUCTIONS,
                          value: IMAGE_FILTERS?.INSTRUCTIONS,
                          label: `${titleCase(IMAGE_FILTERS?.INSTRUCTIONS)}s`,
                        },
                      ]
                    : []),
                ]}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className={isDesktopViewport ? '' : 'width-percent-100'}>
        <CollapsibleFilterWrapper
          searchProps={{
            className: 'search-component',
            getData: instructionLogsData,
            disabled: true,
          }}
          className={clsx(
            'mb-15',
            imageFilter === IMAGE_FILTERS.ALL_IMAGES && 'd-none',
          )}
          items={items}
        />
      </div>

      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        wrapperClassName={clsx(
          'instruction-scroll-wrapper image-list',
          !activityData?.length > 0 && !loading && 'd-flex justify-center',
        )}
        dataLength={activityData?.length}
      >
        <Image.PreviewGroup
          preview={{
            visible: isVisible,
            onVisibleChange: (visible) => {
              setIsVisible(visible);
              if (visible) {
                Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                  label: GA_LABEL.OPENED_IMAGE_VIEWER,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                });
              }
            },
          }}
        >
          {map(activityData, (data) => {
            return (
              <span className="gallery-image" key={data?.id}>
                <Image
                  src={data?.thumbnailUrl}
                  alt="logo"
                  height={isDesktopViewport ? '154px' : '120px'}
                  loading="lazy"
                  preview={{ src: data?.imageUrl }}
                />
                <CommonPopover
                  arrow={false}
                  placement="right"
                  getPopupContainer={() =>
                    // eslint-disable-next-line no-undef
                    document.querySelector('.instruction-scroll-wrapper')
                  }
                  overlayClassName="gallery-image-popover"
                  trigger={[!isDesktopViewport ? 'click' : 'hover']}
                  content={
                    <div>
                      <h2 className="mb-10">Details</h2>
                      <Descriptions
                        layout="horizontal"
                        colon={false}
                        column={1}
                      >
                        <Descriptions.Item label="Tenant">
                          <EllipsisText
                            text={
                              data?.eqc?.tenant?.organizationName ||
                              data?.instruction?.tenant?.organizationName ||
                              '-'
                            }
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label="Project">
                          <EllipsisText
                            text={
                              data?.eqc?.project?.name ||
                              data?.instruction?.project?.name ||
                              '-'
                            }
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={data?.eqcId ? 'EQC' : 'Instruction'}
                        >
                          <EllipsisText
                            text={
                              data?.eqc?.eqcName ||
                              data?.instruction?.name ||
                              '-'
                            }
                          />
                        </Descriptions.Item>
                        {data?.eqcId && (
                          <Descriptions.Item label="Stage">
                            <EllipsisText text={data?.eqcStage?.name || '-'} />
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Image type">
                          {titleCase(data?.assetName) || '-'}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  }
                >
                  <Button
                    shape="default"
                    className="info-button"
                    icon={<InfoIconDark height="16px" width="16px" />}
                    onClick={(e) => {
                      if (!isDesktopViewport) e?.stopPropagation();
                    }}
                  />
                </CommonPopover>
              </span>
            );
          })}
        </Image.PreviewGroup>
      </InfiniteScrollHandler>
    </div>
  );
};

export default Gallery;
