import { Tooltip } from 'antd';
import React from 'react';

const CommonTooltip = (props) => {
  const { getPopupContainer, title, ...rest } = props;
  return (
    <Tooltip
      getPopupContainer={(trigger) =>
        typeof getPopupContainer === 'function'
          ? getPopupContainer(trigger)
          : trigger.parentNode
      }
      title={title}
      {...rest}
    />
  );
};

export default CommonTooltip;
