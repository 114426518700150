import { useLazyQuery } from '@apollo/client';
import * as eva from 'eva-icons';
import { findIndex, includes, keys } from 'lodash';
import React, { useEffect } from 'react';
import { ForwardArrow, ProjectIcon } from '../../assets/svg';
import {
  ACCESS_TYPE,
  ADD_PROJECT_STEPS,
  ADD_PROJECT_STEPS_KEYS,
  ROUTES,
  TAB_KEYS,
} from '../../common/constants';
import useRouter from '../../common/useRouter';
import HasAccess from '../../components/HasAccess';
import LoaderComponent from '../../components/LoaderComponent';
import AddProjectDetails from './components/AddProjectDetails';
import { GET_PROJECT_EQC_TYPE_COUNT } from './graphql/Queries';

const AddProject = () => {
  const {
    navigate,
    params: { projectId, step = ADD_PROJECT_STEPS_KEYS.DETAILS },
  } = useRouter();
  const adProjectSteps = ADD_PROJECT_STEPS;
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  if (!hasInspectionAccess && adProjectSteps.eqctypes) {
    delete adProjectSteps.eqctypes;
  }
  const [fetchProjectEqcTypeCount, { loading }] = useLazyQuery(
    GET_PROJECT_EQC_TYPE_COUNT,
    {
      variables: { filter: { projectId: Number(projectId) } },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res?.projectEqcTypeList?.data?.length) {
          const redirectStep =
            step === ADD_PROJECT_STEPS_KEYS.DETAILS ? TAB_KEYS.EQC : step;
          navigate(`${ROUTES.PROJECTS}/${projectId}/${redirectStep}`);
        }
      },
      onError() {},
    },
  );

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const index = findIndex(keys(adProjectSteps), (item) => item === step);
    if (index !== -1) {
      // eslint-disable-next-line no-undef
      const node = document.getElementById(
        `step-${keys(adProjectSteps)[index]}`,
      );
      if (node) {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });
  useEffect(() => {
    if (projectId && hasInspectionAccess) {
      fetchProjectEqcTypeCount({
        variables: { filter: { projectId: Number(projectId) } },
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!includes(keys(adProjectSteps), step)) {
      navigate(ROUTES.ADD_PROJECTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
  if (loading) return <LoaderComponent />;

  return (
    <div className="add-project-wrapper">
      <div className="project-details-title d-flex justify-between page-header">
        <div className="project-details-title-text d-flex align-center">
          <ProjectIcon
            onClick={() => {
              navigate(ROUTES.PROJECTS);
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1>Add Project</h1>
        </div>
      </div>
      <div className="project-module mb-20">
        <AddProjectDetails />
      </div>
    </div>
  );
};

export default AddProject;
