import { useMutation } from '@apollo/client';
import Gleap from 'gleap';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import useRouter from '../../common/useRouter';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Mutations';

const Logout = () => {
  const { navigate } = useRouter();
  const { dispatch } = useContext(AppContext);
  const [logout, { loading, error, data }] = useMutation(LOGOUT_USER, {
    onError() {},
  });

  useEffect(() => {
    logout();
  }, []);

  Gleap.clearIdentity();

  if (loading) return <LoaderComponent />;

  if (error) {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    navigate(ROUTES.LOGIN);
    return null;
  }

  if (data) {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    navigate(ROUTES.LOGIN);
    return null;
  }

  return null;
};

export default Logout;
