import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Image, Row, Space } from 'antd';
import * as eva from 'eva-icons';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  APPROVAL_TYPE,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  STAGE_STATUS,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import EllipsisText from '../../components/EllipsisText';
import { GET_URL_BY_KEY } from '../profile/graphql/Queries';
import CommentModal from './CommentModal';
import DeleteAdditionalCommentModal from './DeleteAdditionalCommentModal';

const AdditionalComment = ({
  data,
  refetch,
  commentData,
  setCommentData,
  setAdditionalComment,
  additionalComment,
  setIsBannerBlinking,
  approvalType,
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
  const [isAdditionalPoint, setIsAdditionalPoint] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const [imageFileList, setImageFileList] = useState([]);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getImagesSignedUrls, { loading }] = useLazyQuery(GET_URL_BY_KEY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setImageFileList(res?.getImagesSignedUrls);
    },
  });

  useEffect(() => {
    const additionalCommentImage = map(additionalComment?.assets, `key`);
    if (additionalCommentImage?.length > 0) {
      getImagesSignedUrls({
        variables: {
          data: { keys: additionalCommentImage },
        },
      });
    } else {
      setImageFileList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalComment?.assets]);

  const getPhotos = (photos, imageId) => {
    return photos?.length > 0 ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
              });
            }
          },
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      '-'
    );
  };

  return (
    <>
      {showCommentModal && (
        <CommentModal
          showModal={showCommentModal}
          setShowModal={setShowCommentModal}
          data={data}
          refetch={refetch}
          setCommentData={setCommentData}
          commentData={commentData}
          setAdditionalComment={setAdditionalComment}
          additionalComment={additionalComment}
          isAdditionalPoint={isAdditionalPoint}
          setIsAdditionalPoint={setIsAdditionalPoint}
        />
      )}
      <div className="approver-stage-details">
        <div>
          {additionalComment.remark &&
            data?.getEqcStageHistoryWithoutAuth?.status ===
              STAGE_STATUS.APPROVAL_PENDING && (
              <Card className="mb-24" loading={loading}>
                <div>
                  <div className="d-flex justify-between">
                    <div>
                      <h2 className="mb-24">Additional Comment</h2>
                    </div>
                    <div className="action-button d-flex justify-end">
                      <Button
                        type="primary"
                        shape="round"
                        icon={<EditFilled />}
                        onClick={() => {
                          setShowCommentModal(true);
                          setIsAdditionalPoint(true);
                        }}
                        className={`${
                          !isDesktopViewport &&
                          'width-percent-100 justify-center mb-24'
                        }`}
                      >
                        {isDesktopViewport && `Edit`}
                      </Button>
                      <Button
                        type="danger"
                        shape="round"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          setShowDeleteCommentModal(true);
                        }}
                        className={`danger-button ${
                          !isDesktopViewport &&
                          'width-percent-100 justify-center mb-24'
                        }`}
                      >
                        {isDesktopViewport && `Delete`}
                      </Button>
                    </div>
                  </div>

                  {additionalComment?.remark ||
                  additionalComment?.assets?.length > 0 ? (
                    <Card className="comment-card">
                      {additionalComment?.remark && (
                        <div>
                          <div className="d-flex align-center justify-between mb-5">
                            <EllipsisText
                              className="additional-title"
                              text={additionalComment?.remark || '-'}
                            />
                          </div>
                        </div>
                      )}
                      {imageFileList?.length > 0 && !loading && (
                        <Row className="flex-vertical mt-24">
                          <h4>Additional Comment Images</h4>
                          <div
                            className={
                              isDesktopViewport
                                ? 'd-flex justify-between align-end'
                                : 'd-flex flex-vertical'
                            }
                          >
                            <div className="mb-24 mt-5">
                              {getPhotos(
                                imageFileList,
                                additionalComment?.remark,
                              )}
                            </div>
                          </div>
                        </Row>
                      )}
                    </Card>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
            )}
        </div>
        {!additionalComment?.remark &&
          data?.getEqcStageHistoryWithoutAuth?.status ===
            STAGE_STATUS.APPROVAL_PENDING && (
            <div
              className="additional-text"
              onClick={() => {
                if (
                  approvalType === APPROVAL_TYPE.ON_SITE &&
                  !commentData?.length > 0
                ) {
                  setIsBannerBlinking(true);
                } else {
                  setShowCommentModal(true);
                  setIsAdditionalPoint(true);
                }
              }}
            >
              + Add Additional Comment
            </div>
          )}
      </div>
      {additionalComment?.remark && (
        <DeleteAdditionalCommentModal
          showModal={showDeleteCommentModal}
          setShowModal={setShowDeleteCommentModal}
          setAdditionalComment={setAdditionalComment}
          title="Additional Comment"
          name="additional comment"
        />
      )}
    </>
  );
};

export default AdditionalComment;
