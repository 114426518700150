import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Alert, Button, Col, Dropdown, Row } from 'antd';
import clsx from 'clsx';
import { includes, map, sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  InfoIconDark,
  KebabMenu,
  RightIcon,
  WrongIcon,
} from '../../../../../../../../assets/svg';
import {
  ADDONS,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../../common/useRouter';
import { titleCase } from '../../../../../../../../common/utils';
import CanPerform from '../../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../../components/CommonCard';
import CommonPopover from '../../../../../../../../components/CommonPopover';
import CommonTable from '../../../../../../../../components/CommonTable';
import CommonTooltip from '../../../../../../../../components/CommonTooltip';
import EllipsisText from '../../../../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../../../../components/InfiniteScrollHandler';
import Portal from '../../../../../../../../components/Portal';
import {
  DELETE_PROJECT_STAGE_ITEM,
  REORDER_PROJECT_STAGE_ITEM,
  UPDATE_PROJECT_EQC_STAGE_ITEM,
} from '../../../../../../graphql/Mutation';
import { GET_PROJECT_STAGE_ITEM_LIST } from '../../../../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditModal from './AddEditModal';

const RowContext = React.createContext({});

const DraggableCard = ({ id, children, className }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.4 : 1,
  };
  const [dragHandle, ...restChildren] = React.Children.toArray(
    children?.props?.children,
  );
  return (
    <CommonCard cardRef={setNodeRef} className={className} style={style}>
      <div className="d-flex">
        <div
          style={{ cursor: 'grab' }}
          className="mr-5 fw-medium"
          {...attributes}
          {...listeners}
        >
          {dragHandle}
        </div>
        {restChildren}
      </div>
    </CommonCard>
  );
};
const Rows = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <DragIcon
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const StageItemTable = ({
  stageId,
  eqcTypeData,
  isEditable,
  selectedStageData,
}) => {
  const initialStageItemFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'order', order: 'ASC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [stageItemData, setStageItemData] = useState();
  const [deleteStageItemModal, setDeleteStageItemModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [stageItemFilter, setStageItemFilter] = useState({
    ...initialStageItemFilter,
    projectEqcTypeStageId: parseInt(stageId, 10),
  });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [stageItemListData, setStageItemListData] = useState();
  const {
    params: { projectId },
  } = useRouter();
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const [fetchStageItemData, { loading }] = useLazyQuery(
    GET_PROJECT_STAGE_ITEM_LIST,
    {
      variables: { filter: stageItemFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.projectEqcTypeStageItemList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.projectEqcTypeStageItemList?.total,
        };
        if (scrollFlag) {
          const datalist = [...stageItemListData, ...data];
          setStageItemListData(sortBy(datalist, ['order']));
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setStageItemListData(sortBy(datalist, ['order']));
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );
  const handleRefetchAfterDelete = () => {
    const newSkip =
      stageItemListData?.length === 1
        ? Math.max(0, stageItemFilter?.skip - paginationProp?.pageSize)
        : stageItemFilter?.skip;
    setStageItemFilter({
      ...stageItemFilter,
      skip: newSkip,
    });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: newSkip,
        },
      },
    });
  };
  const [updateProjectEqcTypeStageItem] = useMutation(
    UPDATE_PROJECT_EQC_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.DELETE_PROJECT_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.DELETE_PROJECT_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          stage_item_id: selectedData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setDeleteStageItemModal(false);
        handleRefetchAfterDelete();
      },
    },
  );
  const [deleteStageItem] = useMutation(DELETE_PROJECT_STAGE_ITEM, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_CHECKLIST_STAGE_ITEM, {
        label: GA_LABEL.DELETE_PROJECT_CHECKLIST_STAGE_ITEM,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_stage_id: stageId,
        stage_item_id: selectedData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteStageItemModal(false);
      handleRefetchAfterDelete();
    },
  });
  const [reorderStageItem, { loading: reorderLoading }] = useMutation(
    REORDER_PROJECT_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.REORDER_PROJECT_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.REORDER_PROJECT_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        fetchStageItemData({ variables: { filter: stageItemFilter } });
      },
    },
  );
  useEffect(() => {
    if (stageId) {
      setStageItemFilter({
        ...initialStageItemFilter,
        projectEqcTypeStageId: parseInt(stageId, 10),
      });
      setPaginationProp(initialPaginationValue);
      fetchStageItemData({
        variables: {
          filter: {
            ...initialStageItemFilter,
            projectEqcTypeStageId: parseInt(stageId, 10),
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId]);
  const refetchStageDetails = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          projectEqcTypeStageId: parseInt(stageId, 10),
        },
      },
    });
  };
  const handleAddEdit = (record) => {
    if (record) {
      setStageItemData(record);
    } else {
      setStageItemData();
    }
    setShowModal(true);
  };
  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageItemFilter({
      ...stageItemFilter,
      skip,
      limit: pagination.pageSize,
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };

  const handleActiveAndDelete = (record) => {
    setDeleteStageItemModal(true);
    setSelectedData(record);
  };

  const getChangeLogInfo = (record) => {
    const logKeys = ['description', 'photo', 'isActive', 'remark'];
    const changeData = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const element in record?.previousHistory) {
      if (
        includes(logKeys, element) &&
        record[element] !== record?.previousHistory[element]
      ) {
        const messageData = {};
        if (element === 'photo' || element === 'remark') {
          messageData.message = `${titleCase(element)} requirement is turned ${
            record[element] ? 'on' : 'off'
          }`;
        } else if (element === 'isActive') {
          messageData.message = `Checkpoint is ${
            record[element] ? 'activated' : 'deactivated'
          }`;
        } else {
          messageData.message = `Item ${element} updated`;
        }
        changeData.push(messageData);
      }
    }
    if (!record?.projectEqcTypeStageItemId) {
      const message = `New checkpoint added`;
      changeData.push({ message });
    }

    return (
      includes(
        [PROJECT_CHECKLIST_STATUS.PUBLISHED, PROJECT_CHECKLIST_STATUS.ARCHIVED],
        eqcTypeData?.projectEqcType?.status,
      ) &&
      changeData?.length > 0 && (
        <CommonPopover
          overlayClassName="checklist-changelog-popup"
          title="Changes made to the main file"
          trigger={isDesktopViewport ? 'hover' : 'click'}
          placement={isDesktopViewport ? 'right' : 'top'}
          destroyTooltipOnHide
          getTooltipContainer={(triggerNode) => triggerNode?.parentNode}
          content={
            <div onClick={(e) => e.stopPropagation()}>
              <ul>
                {map(changeData, (data) => {
                  return <li>{data?.message}</li>;
                })}
              </ul>
            </div>
          }
        >
          <InfoIconDark
            width={20}
            height={20}
            className={clsx(isDesktopViewport ? 'mr-20' : 'mr-5', 'info-icon')}
          />
        </CommonPopover>
      )
    );
  };
  const getActionButtons = (record) => {
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {isEditable ? (
          <div className={clsx(isDesktopViewport && 'd-flex', 'action-icons')}>
            {getChangeLogInfo(record)}
            <CommonTooltip
              title={
                (eqcTypeData?.status ===
                  PROJECT_CHECKLIST_STATUS?.UNPUBLISHED ||
                  !eqcTypeData?.project?.isActive) &&
                'Edit'
              }
              trigger="focus"
            >
              <Button
                shape="round"
                icon={<EditButton />}
                className={clsx(
                  'edit-button pointer b-0',
                  (eqcTypeData?.status ===
                    PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                    eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                    !eqcTypeData?.project?.isActive) &&
                    'disabled-button',
                )}
                disabled={!record?.isActive}
                onClick={() => {
                  handleAddEdit(record);
                }}
              />
            </CommonTooltip>
            {!includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              eqcTypeData?.projectEqcType?.status,
            ) && (
              <CommonTooltip
                title={
                  (eqcTypeData?.status ===
                    PROJECT_CHECKLIST_STATUS?.UNPUBLISHED ||
                    !eqcTypeData?.project?.isActive) &&
                  'Delete'
                }
                trigger="focus"
              >
                <Button
                  className={clsx(
                    'delete-button pointer b-0',
                    (eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                      eqcTypeData?.status ===
                        PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                      !eqcTypeData?.project?.isActive) &&
                      'disabled-button',
                  )}
                  shape="round"
                  icon={<DeleteButton />}
                  disabled={
                    eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                    eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                    !eqcTypeData?.project?.isActive
                  }
                  onClick={() => {
                    handleActiveAndDelete(record);
                  }}
                />
              </CommonTooltip>
            )}
            {includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              eqcTypeData?.projectEqcType?.status,
            ) && (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: record?.isActive ? 'deactivate' : 'activate',
                      label: (
                        <span onClick={() => handleActiveAndDelete(record)}>
                          {' '}
                          {record?.isActive ? 'Deactivate' : 'Activate'}{' '}
                        </span>
                      ),
                    },
                  ],
                }}
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                trigger={['click']}
              >
                <Button
                  shape="round"
                  className="kebab-icon"
                  icon={<KebabMenu />}
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            )}
          </div>
        ) : (
          <div className="d-flex justify-end">
            <div
              className="pointer text-underline mr-10"
              onClick={() => {
                handleAddEdit(record);
              }}
            >
              View
            </div>
          </div>
        )}
      </CanPerform>
    );
  };
  const columns = [
    ...(isEditable
      ? [
          {
            width: '5%',
            className: 'drag-visible',
            render: () =>
              eqcTypeData?.project?.isActive && (
                <CommonTooltip
                  getPopupContainer={() =>
                    // eslint-disable-next-line no-undef
                    document.querySelector('.ant-table-content')
                  }
                  title="Change order"
                >
                  <div>
                    <DragHandle />
                  </div>
                </CommonTooltip>
              ),
          },
        ]
      : []),
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{stageItemFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'CHECKPOINT',
      dataIndex: 'title',
      key: 'title',
      render: (record) => (
        <div>
          <EllipsisText text={record} />
        </div>
      ),
    },
    {
      title: 'INPUT',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'PHOTOS',
      key: 'photo',
      render: (record) => {
        if (record?.photo === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      },
    },
    {
      title: 'REMARKS',
      key: 'remark',
      render: (record) => {
        if (record?.remark === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      },
    },
    {
      key: 'action',
      render: (record) => getActionButtons(record),
    },
  ];
  const DraggableBodyRow = async ({ over, active }) => {
    if (!active || !over || active?.id === over?.id) return;
    const overIndex = over?.data?.current?.sortable?.index;
    const activeIndex = active?.data?.current?.sortable?.index;
    const data = [...stageItemListData];
    setStageItemListData(arrayMove(data, activeIndex, overIndex));
    try {
      const { errors } = await reorderStageItem({
        variables: {
          data: {
            stageItemIds: [active?.id, over?.id],
            projectEqcTypeStageId: parseInt(stageId, 10),
          },
        },
      });
      if (errors) {
        throw errors;
      }
    } catch (error) {
      setStageItemListData(data);
    }
  };
  const handleRefetch = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: stageItemListData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  return (
    <div id="project-stage-item-module">
      {showModal && (
        <AddEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageItemData}
          selectedStageData={selectedStageData}
          isUpdate={!!stageItemData}
          setStageData={setStageItemData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          stageId={stageId}
          eqcTypeData={eqcTypeData}
          isDisabled={
            eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
            eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
            !eqcTypeData?.project?.isActive
          }
          isEditable={isEditable}
        />
      )}
      {deleteStageItemModal && (
        <DeleteModalStage
          showModal={deleteStageItemModal}
          setShowModal={setDeleteStageItemModal}
          data={selectedData}
          mutation={
            !includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              eqcTypeData?.projectEqcType?.status,
            )
              ? deleteStageItem
              : updateProjectEqcTypeStageItem
          }
          stageId={stageId}
          isDeletable={
            !includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              eqcTypeData?.projectEqcType?.status,
            )
          }
        />
      )}
      {eqcTypeData?.project?.isActive && isEditable && (
        <CanPerform
          action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE_ITEM}
          type={ALLOWED_ACTION_TYPE.BOTH}
        >
          <Portal portalId="stageitem-add-btn">
            <Button
              shape="round"
              type="primary"
              id="add-btn"
              icon={<AddButton />}
              className="add-button"
              disabled={
                eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                !eqcTypeData?.project?.isActive
              }
              onClick={() => {
                handleAddEdit();
              }}
            >
              Add
            </Button>
          </Portal>
        </CanPerform>
      )}
      {selectedStageData?.type === ADDONS.RFI && (
        <Alert
          message={
            <>
              This is a view-only tab. System admin or Project admin can make
              changes by clicking on the Project <b>Settings</b> button and
              click
              <b> RFI</b>.
            </>
          }
          type="info"
          showIcon
          className="mb-10 font-size-12"
        />
      )}
      <DndContext
        onDragEnd={DraggableBodyRow}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
      >
        {isDesktopViewport ? (
          <SortableContext
            items={stageItemListData?.map((i) => i?.id) ?? []}
            strategy={verticalListSortingStrategy}
          >
            <CommonTable
              components={{ body: { row: Rows } }}
              columns={columns}
              dataSource={stageItemListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey="id"
              loading={loading || reorderLoading}
              rowClassName={(record) => {
                if (!record?.isActive) return 'row-disabled';
              }}
            />
          </SortableContext>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading || reorderLoading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            wrapperClassName="stage-item-scroll-wrapper"
            dataLength={stageItemListData?.length}
            skeletonRows={columns?.length - 3}
          >
            <SortableContext
              items={stageItemListData}
              disabled={!isEditable}
              strategy={rectSortingStrategy}
            >
              {map(stageItemListData, (item, index) => {
                return (
                  <DraggableCard
                    id={item?.id}
                    className="stage-card"
                    key={item?.id}
                  >
                    <div className="common-card d-flex">
                      {isEditable && eqcTypeData?.project?.isActive && (
                        <DragHandle />
                      )}
                      <div>
                        <div className="card-header fw-medium">
                          <Row wrap={false}>
                            <Col flex="auto" className="d-flex">
                              <span className="mr-5">{index + 1}.</span>
                              <EllipsisText text={item?.title} />
                            </Col>
                          </Row>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Input:</span>
                            {item?.type}
                          </div>
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-5">Photos:</span>
                            {item?.photo === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-5">Remarks:</span>
                            {item?.remark === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute stage-item-action-btn">
                        {getActionButtons(item)}
                      </span>
                    </div>
                  </DraggableCard>
                );
              })}
            </SortableContext>
          </InfiniteScrollHandler>
        )}
      </DndContext>
    </div>
  );
};

export default StageItemTable;
